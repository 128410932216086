import {
  ILanguageMessages,
  ITranslateCreators,
  ITranslateCustomSolution,
  ITranslateDashboard,
  ITranslateDisabled_user,
  ITranslateDropZone,
  ITranslateErrorMessages,
  ITranslateEvaluation,
  ITranslateFileList,
  ITranslateFinancial,
  ITranslateForm_Errors,
  ITranslateGlobal,
  ITranslateGoBackBtn,
  ITranslateNavMenu,
  ITranslateOptionsBtn,
  ITranslateOrderBox,
  ITranslateOrders,
  ITranslateProfile,
  ITranslateSearchBar,
  ITranslateSidebar,
  ITranslateSolutions,
  ITranslateStandardSolution,
  ITranslateSuccess,
  ITranslateSurgicalPlanning,
  ITranslateTopBar,
  ITranslateUserRegistration,
  ITranslateFixiter,
  ITranslatePrinter,
  ITranslateCourses,
  ITranslateLogin,
  ITranslateTips,
  ITranslateAddress,
  ITranslatePerson,
  ITranslateCoins,
  ITranslateUserProfile,
  ITranslateNoInfo,
  ITranslateLanguage,
  ITranslateBills,
  ITranslateTransactions,
} from "./dtos";

const language: ITranslateLanguage = {
  userLanguage: "en",
};

const transactions: ITranslateTransactions = {
  header: "Transactions",
};

const noInfo: ITranslateNoInfo = {
  partner: "You don't have any registered partners yet.",
  patient: "You don't have any registered patient yet.",
  address: "You don't have any registered address yet.",
  card: "You don't have any registered card yet.",
};

const bills: ITranslateBills = {
  valueToBePaid: "Value to be paid:",
  completePayment: "Pay remaining invoice",
  value: "Value",
  card: "Card",
  ticket: "Ticket",
  pix: "PIX",
  paymentCard: "Payment card",
  didntFindYourCard: "Didn't find your card?",
  didntFindYourAddress: "Didn't find your address?",
  billing_address: "Billind address",
  pay: "Pay",
  changePaymentMethod: "Change payment method",
  register: "Register one",
  linkToAccessTicket: "Link to access the ticket PDF",
  barCode: "Bar code",
  pixCopyAndPaste: "PIX copy and paste",
  bills: "Bills",
  requestBill: "Request bill",
  latePaymentInfo:
    "*Payments made after due date will have 10% penalty and 2% interest",
  dataSentByMail: "Data sent by email",
  error: {
    payment: "Unable to make payment",
    transaction: "An error occurred in the transaction",
    mailBill: "Unable to make payment",
    insufficientFunds: "Insufficient funds",
  },
  success: {
    payment: "Payment made successfully",
    mailBill: "Charge requested successfully",
  },
};

const userProfile: ITranslateUserProfile = {
  addresses: "My addresses",
  patients: "My patients",
  partners: "My partners",
  paymentInfos: "Cards",
  users: "My users",
  closure: "Financial",
  myProfile: "My profile",
  addressLabel: "Addresses",
  patientLabel: "Patients",
  userLabel: "Users",
  partnerLabel: "Partners",
  examsLabel: "Exams",
  closureResume: "Closure resume",
  ordersMade: "Orders made",
  ordersPaid: "Orders paid",
  descriptions: {
    fixPoint: "Number of orders you are provider.",
    closure: "Orders placed during:",
    ordersPaid: "Total in orders that were paid for by you.",
    ordersMade: "Total orders placed during the closing period.",
    ordersCourtesy: "Total on orders that have been marked as courtesy.",
    fixPointPopover: "Check which orders",
    closurePopover: "Check orders made in the current period",
  },
  my_profile: "My Profile",
  profile_picture: {
    register: "Register Profile Picture",
    adjust: "Click to register your profile picture and adjust it.",
    success: "Profile picture registered successfully!",
    error:
      "Error while registering your profile picture. Please try again later.",
    register_profile_picture: "Register",
  },
  password: {
    success: "Password changed successfully!",
    error: 'Wasn"t possible to change your password.',
  },
  edit_profile_data: "Edit Personal Data",
  edit_user_data: "Edit User Data",
  edit_licensee_data: "Edit Licensee Data",
  no_permission: 'You don"t have permission to edit the licensee data.',
  yes: "Yes",
  no: "No",
  profile_data: "Personal Data",
  user_data: "User Data",
  licensee_data: "Licensee Data",
  personal_data: {
    document: "Document Number",
    phone: "Cellphone",
    country: "Country",
    state: "State",
    city: "City",
    success: "Personal Data Updated Sucessfully!",
    naturalPerson: "Natural Person",
  },
  data_user: {
    email: "E-mail",
    name: "Name",
    update: "Update User Data",
  },
  data_licensee: {
    fantasy_name: "Fantasy Name",
    enterprise_name: "Company Name",
    document: "Document Number",
    situation: "Situation",
    phone: "Phone",
    company_phone: "Company Phone",
    contract: "Contract",
    printer: "Printer",
    fixpoint: "FixPoint",
    update: "Update Licensee Data",
    manager_name: "Manager Name",
    instagram: "Instagram",
    region: "Region",
    printers: "Printers",
  },
  selectedPrinters: "Selected Printers",
  noPrinterSelected: "No Printer Selected",
  actualClosure: "Opened closure",
  previousClosure: "Previous closure",
};

const fixiterOnboarding: ITranslateFixiter = {
  introduction:
    "Hello Fixiter! Ready to start offering the best to your patients?",
  proceed: "Continue",
  initialKit: "Request your initial kit",
  firstStepHeader: "Find out more about Fix it technology in this video:",
  secondStepHeader: "All about our 3D printing technology",
  thirdStepHeader: "Now let's learn how to apply FistFix",
  firstDescription:
    "But before we start, let's explain how it's going to be, okay?",
  description: "Our startup process consists of two steps:",
  stepOne: "Fix it technology",
  stepTwo: "Application of the Main Orthosis (FistFix)",
  stepThree: "Software Training",
  startTheOnboard: "START",
  startToUseTheApp: "START TO USE THE APP",
  sendCertificate: "Send picture of my diploma",
  send: "Submit",
  cancel: "Cancel",
  sendDiploma: "Send diploma",
  chooseFile: "Select a file to send (.png, .jpeg, .jpg)",
  selectSolutions: "SELECT SOLUTIONS",
  conclusionHeader: "It's not long before you become a Fixiter!",
  document_success: "Document sent successfully!",
  document_failed:
    "Wasn't possible to send the document! Please contact the SAF.",
  firstConclusionDescription:
    "But congratulations on finishing the training of the platform, we can already say that you are part of Fix it and the revolution in health, our welcome.",
  conclusionDescription:
    "Now we are going to validate your information to release your access as a Fixiter, I need you to send a photo of your diploma to our WhatsApp, we will get back to you within 24 hours. So don't waste time, click the button below to be redirected and validate your user, once approved, you will be able to place your first order and deliver the best experience for your patient!",
  attachments: {
    header: "Attachments",
    portfolio: "Download portfolio",
    tools: "Link to purchase tools and supplies",
    startKit: "Order the Starter Kit",
    goodPractices:
      "Guide to good practices with the Fix it splint, for the professional to deliver to the patient",
    heatBlower: "Heat blower",
    scissors: "Scissors",
    tubularMesh: "Tubular mesh (10cm diameter)",
    measuringTape: "Measuring tape",
    velcro: "Velcro",
  },
  stepTwoDescription: `• In this next step, you will need to follow some guidelines:
  The application is very simple, but you should have the necessary tools at hand,
  If not, just access the link below:`,
  firstConclusionWelcome: `Our welcome to the future of health!`,
  initialKitDescription: `• Now with all the guidelines followed, let's continue with the video of the application of our PunhoFix orthosis?`,
  attachmentsToolDescription: `• The links below are references for purchase, most of
  supplies and tools you can find in stores close to
  you, reducing shipping time and cost.`,
  attachmentsStartKitDescription: `• To practice, just request your Starter Kit below, remembering that it is completely free.`,
};

const userRegistration: ITranslateUserRegistration = {
  connector: "and",
  stepOne: "Create account",
  stepTwo: "Registration data",
  stepThree: "Business data",
  stepFour: "Plans",
  nextStep: "Next",
  prevStep: "Back",
  name: "Name",
  email: "E-mail",
  password: "Password",
  password_confirmation: "Password confirmation",
  fillCodes: "Enter the code we sent to your email",
  fillNameAndEmail: "Enter your email and password",
  fillData: "Fill your data",
  country: "Country",
  cpf: "Individual registration",
  cellphone: "Cellphone",
  state: "State",
  city: "City",
  termsOfUse: "I have read and accept the",
  terms: "Terms of use",
  policy: "Privacy policy",
  companyNumber: "Employer Identification Number (EIN)",
  companyData: "Fill your company data",
  companyName: "Company name",
  tradingName: "Trading name",
  continueAsNaturalPerson: "Continue as natural person",
  plans: "Choose your plan",
  choosePlan: "Choose plan",
  choosedPlan: "Selected",
  errorChoosePlan: "Choose a plan",
  errorAsigneePlan: "There was an error with the plan",
  errorCreatePerson: "An error occurred while creating the user",
  termsError: "There was an error while loading the document",
  validCode: "Enter a valid code.",
  emailInUse: "Email already in use",
  codeExpires: "Your code expires in: ",
  sendCode: "Resend code",
  sendedCode: "Resend code",
  codeExpired: "Expired code.",
  professional_number: "Class council number",
  expertise: "Specialty",
  instagram: "Instagram",
  notOperational: "Oops! Fix it does not operate in your region yet.",
  noOperationalDescription:
    "Contact our customer service and learn how to bring Fix it to you",
  contactUs: "Contact us",
  finishLater: "Finish later",
  fillManagerData: "Fill in your manager data",
  confirm: "Confirm",
};

const login: ITranslateLogin = {
  email: "E-mail",
  password: "Password",
  forgotPassword: "Forgot my password",
  login: "Login into platform",
  register: "Register",
  submit: "Submit",
  dontHaveAccount: "Don't have an account?",
  recover_password: "Password Recovery",
  send_code: "Send redefinition code",
  new_password: "New Password",
  new_password_confirmation: "Confirm the New Password",
  change_password: "Change Password",
  password_changed: "Password Changed Successfuly!",
  starting: "Starting",
};

const tips: ITranslateTips = {
  title: "New features",
  create: "New tip",
  edit: "Edit",
  delete: "Delete",
  createButton: "Create tip",
  editButton: "Save",
  name: "Name",
  users: "Users",
  type: "Type",
  description: "Description",
  descriptionError: "An error occurred while trying to load the data.",
  success: "Tip created with success",
  error: "An error ocurred while creating the tip",
  page: "Page",
  updateButton: "Update",
  updateError: "An errror ocurred while editting the tip",
  changeStateError: "An error ocurred whilte trying to update the tip.",
  specificUrl: "Parameter",
  activateTip: "Activate tip",
  cancel: "Cancel",
  confirm: "Confirm",
  deleteTip: "Delete tip",
  deleteError: "An error occurred while trying to delete the tip",
  globalWarning:
    "We identified that there is already a global tip activated, do you want to deactivate it to activate this one?",
  pageWarning:
    "We identified that there is already a tip for this page activated, do you want to deactivate it to activate this one?",
  deleteWarning:
    "This tip is still active, are you sure you want to delete it?",
  new: "News",
  dontShowAgain: "Dont't show again",
};

const creators: ITranslateCreators = {
  solutions: "Manage solutions",
  make: "Produce",
  addFile: "Add file",
  side: "Side",
  part: "Part",
  size: "Size",
};

const global: ITranslateGlobal = {
  underConstruction: "Available soon",
  solutions: "Solutions",
  situation: "Situation",
  undo: "Undo",
  regions: {
    southeast: "Sudeste",
    south: "Sul",
    north: "Norte",
    northEast: "Nordeste",
    midwest: "Centro-Oeste",
    international: "International",
  },
  customerSituation: {
    effected: "Sale effected",
    implementation: "Under implementation",
    operational: "In operation",
    training: "In training",
    onboarding: "Onboarding",
  },
  licensees: {
    header: "Licensees",
    create: "Create Licensee",
    edit: "Edit licensee",
    field: {
      document_number: "CNPJ/CPF",
      email: "E-mail",
      phone: "Phone",
      instagram: "Instagram",
      is_public: "Public",
      natural_person: "Person",
      legal_entity: "Company",
      cpfMask: "999.999.999-99",
      cnpjMask: "99.999.999/9999-99",
      manager_name: "Manager",
      is_test: "Test licensee",
    },
  },
  designers: {
    header: "Designers",
    create: "Create designer",
    edit: "Edit designer",
  },
  configs: {
    header: "General configs",
    createUserTerms: "New user terms",
    solutionFields: "Solution fields",
    createSolutionField: "New field",
    createSolutionCategory: "New category",
    solutionCategories: "Categories of",
    editSolutionField: "Edit ",
    editSolutionCategory: "Edit ",
    docs: {
      header: "Documents - Contract {{contract}}",
      label:
        "This documents are available in all the App by contracts terms like terms of use or privacy policy.",
    },
    printers: {
      header: "Approved printers",
      edit: "Edit printer",
      create: "Create printer",
      firstStep:
        "In this first step, you need to have the Printer Driver installed (Mac or Windows), so, with the printer connected to a USB port, click on the button below.",
      secondStep:
        "In the second step, we will carry out some movements on the printer, completely offline, that is, this process works even when disconnected from the internet. Which will test communication with the device.",
      conclusion:
        "Now, you're all set to start printing. Feel free to heat the table and extruder here or directly from the printer.",
      heatBed: "Heat bed 65ºC",
      heatExtruder: "Heat extruder 210ºC",
      configure: "Printer configure",
      connect: "Connect printer",
      notfound: "You didn't find your printer?",
      manualSearch: "Manual here",
      test: "Start test",
      baudRateChangeSucess: "Baud Rate sucessfully changed",
      changeBaudRateTo: "Change Baud Rate to",
    },
    users: {
      header: "Internal users",
      edit: "Edit user",
      create: "Create user",
    },
    tips: {
      header: "New features",
    },
  },
  novalue: "Clear",
  status: {
    label: "Active records",
    active: "Actives",
    deactive: "Deactives",
    registered: "Registereds",
  },
  sides: {
    ESQ: "Left",
    DIR: "Right",
    UNI: "Unique",
  },
  roles: {
    LICENSEE: "Licensee",
    FIXITER: "Fixiter",
    CREATOR: "Product Designers",
    ADMIN: "Administrator",
    SAF: "Attendance (SAF)",
    EMPLOYEE: "User",
  },
  password: {
    password: "Actual Password",
    password_confirmation: "Confirm the New Password",
    new_password: "New Password",
    save_changes: "Save Changes",
    change_password: "Change Your Password",
  },
};

const dashboard: ITranslateDashboard = {
  header: "Reports",
  requestsDay: "Today's orders",
  requestsMonth: "Orders of the month",
  requestsSevenDays: "Orders of the last 7 days",
  requestsYear: "Orders in",
  revenueYear: "Revenue in",
  clickSevenDays: "Website clicks at last 7 days",
  soon: "SOON",
  nps: "NPS",
  months: {
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
  },
  newPatients: "New patients",
  bill: "Bill",
  filamentUse: "Filament consumption",
  filamentInfo: "Average calculated based on standardized orders",
  error: "Failed to load dashboard information",
  caseIncidence: "Case incidence",
  mostAskedSolutions: "Most requested solutions",
  patientsInfo: "Patients registered during selected period",
  month: "Month",
  apply: "Apply",
  period: "Period",
};

const solutions: ITranslateSolutions = {
  header: "Fix it solutions",
  select: "Select",
  whichSize: "WHICH SIZE?",
  aplicationVideo: "Application video",
  categories: {
    header: "Which solution category do you wish?",
  },
  solutions: {
    header: "Choose a solution",
  },
  download: "Download",
  unique_part: "Unique",
  image_upload_title: "Update image",
  image_upload_subtitle: "Solution's display image",
  save_new_image: "Save new image",
  edit_image: "Change the solution image",
  download_file_enable: "Enable",
  download_file_upload_title: "Update file",
  download_file_upload_subtitle: "Solution's download file",
  custom_update_title: "Update custom",
  custom_update_subtitle: "Custom solution data",
  size_patterns: "Size standards",
  standard_update_title: "Update standard",
  standard_update_subtitle: "Standard solution data",
  is_custom: "Customizable solution",
  is_standard: "Standardizable solution",
  is_download: "Downloadable solution",
  name: "Solution name",
  edit: "Edit solution",
  category: "Category",
  field: "Field",
  form_type: "Form type",
  suggested_price: "Suggested price",
  side: "Side",
  number_sides: "Sides",
  updateButton: "Save data",
  number_parts: "Parts",
  part: "Part",
  number_measures: "Measures",
  link_guide: "Measures guide URL",
};

const success: ITranslateSuccess = {
  header: "Customer success",
};

const orders: ITranslateOrders = {
  financial_status: {
    order_status: "Order status",
    paid: "Paid",
    not_paid: "Billed",
    courtesy: "Discounted",
    success: "Order status updated successfully.",
    error: "An error occurred while trying to update the order status.",
  },
  confirmation: {
    service_number: "Service number",
    confirm: "Confirm order details",
    patient: "Patient",
    years: "years",
    profissional: "Professional",
    partner: "Partner",
    solution: "Solution",
    custom: "Custom",
    standard: "Standard",
    measures: "Measures",
    bodyPart: "Body part",
    injuryType: "Injury type",
    surgeryDate: "Surgery date",
    target: "What should be targeted",
    markAsUrgency: "Mark as urgent",
    urgencyDetails: "Requests with unjustified urgency will be charged double.",
    totalValue: "Total value",
    justification: "Justification",
    responsabilityConfirmation:
      "I declare that the data provided are my responsibility and that they are all correct.",
    cancel: "Cancel",
    confirmAndFinish: "Confirm and finalize",
  },
  urgency: {
    requested: "Urgency request order",
    requestedInfo: "An ugency request has been made for the order",
    requestedApprove: "do you want to approve it?",
    approve: "Approve",
    refuse: "Refuse",
    popoverRequested: "Urgency requested",
    popoverApproved: "Urgency approved",
    popoverNonJustified: "Urgency non justified",
    additionalValueJustification:
      "Custom orders will have an increase of {{value}} {{currency}}, in case of urgency: {{doubleValue}} {{currency}}",
  },
  actions: {
    new: "NEW",
    edit: "EDIT",
    save: "SAVE",
    close: "CLOSE",
    discart: "DISCART",
    upload: "UPLOAD",
  },
  pending_info: {
    text: "Outstanding information",
  },
  patient_section: {
    search: {
      error: "Got a error while request patient data.",
    },
    empty_title: "Patient data",
    empty_subtitle: "Age, weight, phone...",
  },
  partner_section: {
    search: {
      error: "Got a error while request professional data.",
    },
    empty_title: "Partner data",
    empty_subtitle: "Phone, e-mail...",
    entities: {
      natural_person: "NATURAL PERSON",
      legal_entity: "LEGAL ENTITY",
    },
  },
  solution_section: {
    empty_title: "FINALIZE AND SEND",
    empty_subtitle: "Describe the product: color, measurements...",
    measurement_guide: "Consult measurement guide",
  },
  exam_section: {
    empty_title: "ASSESSMENT DATA",
    empty_subtitle: "Professional, diagnostic, member...",
    professional_label: "Professional's data",
  },
  planit_section: {
    title_template: "PLANNING ",
    empty_title: "PLANNING",
    empty_subtitle: "Professional, injury, files...",
    doctor_subsection_title: "Physician in charge",
    evaluation_subsection_title: "Evaluation",
    files_subsection_title: "Files",
  },
  order_type: {
    is_patient: "FOR MY PATIENT",
    is_partner: "FOR MY PARTNER",
    is_fixiter: "FOR THE FIXITER",
  },
  create: {
    custom: {
      header: "Custom order",
      header_template: "Custom ",
    },
    standard: {
      header: "Standard order",
      header_template: "Standard ",
    },
    plan: {
      header: "Surgical plan",
      header_template: "Surgical plan",
    },
  },
  printChoose: {
    header: "Print control",
    available_info: "Available {{available}} of {{quantity}}",
    standard: "Standard",
    sides: "Side",
    save: "Save and prepare to print",
    prepare: "Prepare to print",
    error: "Upsy! Print unavailable",
    advice: "Waiting data",
    waitingchoice: "Waiting for choice",
    useStandardFileOption: "Keep {{print_option}}",
    noprinter: "Please, select a printer",
    proceed: "Continue printing - {{file_choice}}",
    end: "Close print - {{file_choice}}",
    endAndSelect: "Close and choose new one",
    add: "Add choice",
    progress: "Show me the progress",
    demand: "Print demand - {{file_choice}}",
    waiting: "FixPoint printing - {{file_choice}}",
    notStarted: "Waiting for FixPoint",
    completed: "Print completed {{file_choice}}",
    printControl: {
      header: "Choose peace",
      selectStream: "Print {{print_option}}",
      completed: "Print completed {{print_option}}",
      tryagain: "Try again {{print_option}}",
      advice: "Waiting data",
      error: "Can't start print",
      empty: "There is no peace to print",
      print: "Config print",
      printing: "Printing in progress {{print_option}}",
      preHeatingBedAndExtruder: "Pre-heating bed and extruder",
    },
  },
  creator: "Designer",
  licensee: "Costumer",
  print: "Printing options",
  download: "Download options",
  view: "Order details",
  printHistory: "Printing history",
  printManagement: "Print management",
  printStarting: "We are ready to print,",
  downloadStarting: "Your download is starting,",
  printingSuccess: "Check the data",
  clickToDownload: "Didn't start? Click here to download.",
  printingData: "Printing data",
  printingTime: "Time:",
  materialCost: "Cost:",
  materialWeight: "Wheight:",
  custom_production_update_title: "Customization upload",
  custom_production_update_subtitle: "Production data",
  start_development: "Start development",
  call_review: "Call review",
  is_cancelled: "Cancelled order",
  commit_review: "Commit review",
  header: "Orders",
  as_provider: "FixPoint demands",
  date: "Date",
  patient: "Patient",
  partner: "Partner",
  solution: "Solution",
  type: "Type",
  category: "Category",
  quantity: "Quantity",
  printer: "Printer",
  state: "Situation",
  price: "Total amount",
  total: "Total ({{order_type}})",
  files: "Files",
  selectPrinter: "Select a printer...",
  sendToPrinter: "Send to printer",
  conclude: "Finish",
  didNotFindPrinter: "Didn't find your printer at the list?",
  printComplete: "Is the print complete?",
  didNotFoundNavigator: "Look like your browser isn't Chrome!",
  chromeDownload1:
    "For a better FixIt system utilization we recomend you use it!",
  chromeDownload2: "Or you can donwload it here:",
  clickToProceed: "Click to proceed",
  patientNotSet: "Not informed",
  status: {
    processing: {
      value: "processing",
      optionText: "Processing",
    },
    development: {
      value: "development",
      optionText: "Developing",
    },
    review: {
      value: "review",
      optionText: "In Review",
    },
    cancelled: {
      value: "cancelled",
      optionText: "Cancelled",
    },
    completed: {
      value: "completed",
      optionText: "Completed",
    },
    expired: {
      value: "expired",
      optionText: "Expired",
    },
  },
  fields: {
    status: "Status",
  },
  cancelConfirmation: "Are you sure you want to cancel the order?",
  qtd: "QTY",
  order_provider: {
    requester: "Requester",
    order_data: "Order data",
    company_name: "Company name",
    fantasy_name: "Fantasy name",
    responsible: "Responsible",
    location: "Location",
  },
  filter: {
    sublicensee: "Sublicensee",
  },
  planit: {
    submit_file_upload: "Upload image",
    file_upload: "Image upload",
  },
};

const profile: ITranslateProfile = {
  header: "My unity",
  unit: "Unity",
  email: "Email",
  state: "Situation",
  region: "Region",
  managerName: "Manager name",
  phone: "Phone",
  instagram: "Instagram",
  editProfile: "Edit profile",
  changePassword: "Change password",
  printers: "Printers",
  addPrinter: "Add a printer",
};

const sidebar: ITranslateSidebar = {
  reports: "Reports",
  solutions: "Solutions",
  requests: "Orders",
  files: "Files",
  success: "Success",
  designers: "Designers",
  licensees: "Licensees",
  config: "Config.",
  signout: "Exit",
  prices: "Prices",
  plans: "Plans",
  onboarding: "Onboarding",
  courses: "Courses",
};

const navMenu: ITranslateNavMenu = {
  reports: "Reports",
  solutions: "Solutions",
  requests: "Orders",
  files: "Files",
  success: "Success",
};

const topbar: ITranslateTopBar = {
  coinFix: {
    start: "You have",
    end: "FixCoin",
  },
  notification: "Uhul, you have {{ quantity }} notifications!",
  editProfile: "Edit profile",
  signout: "Exit",
  noprinter: "No Printer",
  fixpoint: "FixPoint",
  change_password: "Change Password",
  my_users: "My Users",
  filamentData: "Filament Data",
  addPerson: "Add personal data",
};

const dropZone: ITranslateDropZone = {
  dragActive: "Click or drag to add",
  error: "File not suported",
  success: "Drop the files here",
};

const fileList: ITranslateFileList = {
  delete: "Delete",
};

const goBackBtn: ITranslateGoBackBtn = {
  back: "Back",
};

const optionsBtn: ITranslateOptionsBtn = {
  successMessage: "Order sucessfull canceled",
  evaluation: "Access review",
  problem: "I had a problem",
  cancel: "Cancel order",
  printHistory: "Print history",
};

const orderBox: ITranslateOrderBox = {
  date: "Date",
  patient: "Patient",
  solution: "Solution",
  category: "Category",
  quantity: "Quantity",
  printer: "Printer",
  state: "Situation",
  price: "Total amount",
  files: "Files",
};

const searchBar: ITranslateSearchBar = {
  placeholder: "Search something...",
};

const surgicalPlanning: ITranslateSurgicalPlanning = {
  personalize: "Surgical planning",
  nullTargetField: "Inform what should be segmented",
  segmentation: "Segmentation",
  doctorInformation: "Information of the doctor/Responsible surgeon",
  partOfTheBody: "Body part",
  lesionType: "Lesion type",
  whatShouldBeSegmented: "What should be segmented",
  surgeryDate: "Surgery date",
};

const standardSolution: ITranslateStandardSolution = {
  buy: "Default",
  request: "Request",
  size: "Size",
  side: "Side",
  quantity: "Quantity",
  printingInfo: "Print info",
  selectPrinter: "Printer",
};

const customSolution: ITranslateCustomSolution = {
  buy: "Custom",
  updated_at: "Update to",
  updated_at_suffix: "min. ago",
  noFilePrice: "Waiting for form data for price calculation",
  noSelectedPrinter: "No printer selected",
  noStandardFile: "There is no file available for the given configuration",
  totalPrice: "Total:",
  previous: "Previous",
  next: "Next",
  successToast: "Order successly made",
  errorToast: "Error placing order! Details :",
  sendOrder: "Place order",
  personalize: "Personalize",
  selectPrinter: "Select printer",
  step: "Step",
  of: "of",
  patientData: "Patient data",
  fullName: "Full name",
  company_name: "Corporate name",
  professional_number: "Professional license number",
  cpf: "Document number",
  cnpj: "Company number",
  cep: "Postal code",
  cepMask: "99999-999",
  contact: "Contact",
  contactMask: "(99) 99999-9999",
  measuresError: "Please, add the measures",
  nameError: "Enter the full name of the patient",
  professionalNameError: "Enter the doctor name",
  diagnosisError: "Describe the realized diagnose",
  age: "Age",
  months: "Month",
  weight: "Weight (kg)",
  height: "Height (cm)",
  responsibleName: "Responsible name",
  responsiblePlaceholder: "If underage or have any disability",
  phone: "Phone",
  phonePlaceholder: "(00) 00000-0000",
  email: "E-mail",
  emailPlaceholder: "example@usefixit.com",
  evaluation: "Evaluation",
  hasIndication: "Have indication",
  previousUse: "Previous use of orthosis",
  professionalName: "Professional name who made the evaluation",
  occupation: "Actuation area",
  diagnosis: "Diagnosis",
  limb: "Limb",
  previousOrthesis: "Previous splint",
  previousOrthesisPlaceholder: "Ex.: Plaster, thermoplastic plate, etc...",
  finishingOrder: "Finishing order",
  measures: "Measures",
  measuresGuide: "Check the measure guide",
  filamentColor: "Filament color",
  observations: "Notes",
  observationsPlaceholder:
    "Ex.: Swollen member, measurements of the contralateral member, etc...",
  colors: {
    aluminum: "Aluminum",
    yellow: "Yellow",
    skyBlue: "Sky Blue",
    cobaltBlue: "Cobalt Blue",
    darkBlue: "Dark Blue",
    metalicBlue: "Metalic Blue",
    oceanBlue: "Ocean Blue",
    white: "White",
    bronze: "Bronze",
    gray: "Gray",
    golden: "Golden",
    silver: "Silver",
    black: "Black",
    orange: "Orange",
    oilOrange: "Oil Orange",
    lilac: "Lilac",
    ivory: "Ivory",
    natural: "Natural",
    pistachio: "Pistachio",
    pink: "Pink",
    purple: "Purple",
    darkPurple: "Dark Purple",
    metalicPurple: "Metalic Purple",
    green: "Green",
    darkGreen: "Dark Green",
    forestGreen: "Forest Green",
    tiffanyGreen: "Tiffany Green",
    red: "Red",
  },
  jobs: {
    physiotherapist: {
      value: "physiotherapist",
      optionText: "Physiotherapist",
    },
    orthopedist: {
      value: "orthopedist",
      optionText: "Orthopedist",
    },
    occupationalTherapist: {
      value: "occupationalTherapist",
      optionText: "Occupational Therapist",
    },
    other: {
      value: "other",
      optionText: "Other",
    },
  },
  sides: {
    left: "Left",
    right: "Right",
    unique: "Unique",
  },
  diagnostic: {
    newDiagnosisLabel: "Didn't find a diagnostic?",
    diagnosisRegisters: "Register here",
    newDiagnosisModalLabel: "Diagnosis registration",
    name: "Title",
    icd: "ICD",
    register: "Register",
  },
  gender: {
    gender: "Gender",
    male: { optionText: "Male", value: "male" },
    female: { optionText: "Female", value: "female" },
  },
};

const evaluation: ITranslateEvaluation = {
  patient: "Patient",
  name: "Name",
  age: "Age",
  months: "Months",
  weight: "Weight",
  height: "Height",
  evaluation: "Evaluation",
  diagnosis: "Diagnosis",
  previousOrthesis: "Previous orthesis use",
  indication: "Indication",
  observations: "Observations",
  header: "Evaluation",
  requirements: "Requirements",
  printer: "Printer",
  side: "Side",
  bodyPart: "Body part",
  segmentaion: "Segmentation",
  injuryType: "Injury type",
  surgeryDate: "Surgery date",
  urgency: "Urgency",
  urgencyCause: "Urgency cause",
  urgencyNotComprooved: "Urgency not comprooved",
  measure: "Measure",
  planning: "Planning",
  professional: "Professional",
  images: "Images",
  files: "Files",
};

const form_errors: ITranslateForm_Errors = {
  alert: "Check the fields with error.",
  required: "${path} This field is required",
  minRequired: "${path} Required",
  matchPassword: "${path} Passwords do not match",
  validEmail: "${path} Enter a valid email",
  validCountry: "${path} Select a country",
  invalidValue: "${path} Enter a valid value",
  passwordLength: "${path} Password must be at least 8 characters",
  passwordCharacters:
    "${path} Password must contain at least one uppercase letter, one number, and one special character",
};

const disabled_user: ITranslateDisabled_user = {
  principal: "Your access is disabled due financial pendencies",
  secundary: "Please, contact the SAF",
  disabledFixiter: "Your plan has expired!",
  disabledFixiterSecondary: "Contact the SAF to renew",
};

const financial: ITranslateFinancial = {
  prices: {
    header: "Prices",
    create: "Create price",
    edit: "Edit price",
    update: "Update",
    save: "Save",
    country: {
      default: "ALL",
    },
    confirm: {
      deactivate: "Are you sure you want to disable this price?",
      activate: "Are you sure you want to enable this price?",
    },
    field: {
      country: "Country",
      order_type: "Order type",
      solution_type: "Solution type",
      currency: "Currency",
      file_price: "Price",
      solution: "Solution",
      plan_name: "Plan's Name",
      trial_period: "Period",
      numberOfSolutions: "Number of solutions",
      active: "Active",
      inactive: "Inactive",
    },
    solution_type: {
      standard: "Standard",
      custom: "Custom",
      plan: "Surgical plan",
      default: "All",
    },
    order_type: {
      is_patient: "B2C",
      is_partner: "B2B",
      is_fixiter: "FIXITER",
    },
    plan_type: {
      demo: "Demo",
      advanced: "Advanced",
      basic: "Basic",
      old_signatures: "Old signature",
    },
  },
  plans: {
    confirm: {
      deactivate: "Are you sure you want to disable this plan?",
      activate: "Are you sure you want to enable this plan?",
    },
    financial: {
      free: "Are you sure you want to set the plan as free?",
      paid: "Are you sure you want to set the plan as paid?",
    },
  },
  situation: {
    paid: "Paid",
    not_paid: "Billed",
    courtesy: "Courtesy",
  },
  error: {
    get_prices: "There was an error while trying to reach the solution prices!",
    get_signature: "You don't have a signature yet! Please contact the SAF.",
  },
};

const error: ITranslateErrorMessages = {
  printings: {
    completed: "Print already done",
    quantity: "Reached the print limit",
    missingpart: "Missing part of the print, contact SAF service",
  },
};

const printer: ITranslatePrinter = {
  setup: "Click to setup",
  ready: "Ready to print",
  erase: "Clear",
  filament: {
    success: "Filament configured successfuly!",
    error: "An error ocurred while configuring the filament. Try again.",
    filamentConfigs: "Filament Configurations",
    filamentDescription:
      "Please, insert your buying filament price data so we can calculate correctly your material costs of each printing.",
    buyingCoin: "Purchase Currency",
    buyingCost: "Filament Price Cost per Kilogram",
    configure:
      "To see the cost informations, please configure your filament data.",
  },
};

const courses: ITranslateCourses = {
  names: {
    fixit: "Fix it Technology",
    punhofix: "PunhoFix application training",
  },
  descriptions: {
    fixit: "Learn about Fix it and 3D printings!",
    punhofix: "Learn how to apply one of our greatest solutions!",
  },
};

const address: ITranslateAddress = {
  address_options: {
    address: "Address",
    edit: "Edit address",
    delete: "Delete address",
    new_address: "Add address",
    create_as: "Register as",
    created_as: "Registered como:",
    save: "Save",
    att: "Update",
    close: "Close",
    cancel: "Cancel",
  },
  my_addresses: "My Addresses",
  billing_address: "BILLING ADDRESS",
  deliver_address: "DELIVER ADDRESS",
  address: "Public place",
  cep: "Zip code",
  number: "Number",
  complement: "Complement",
  district: "District",
  city: "City",
  state: "State",
};

const person: ITranslatePerson = {
  manager: "Manager",
  user: "User",
  edit_personal_data: "Edit personal pata",
  delete_user: "Delete user",
  name: "Name",
  cpf: "Individual registration",
  cellphone: "Cellphone",
  expertise: "Role",
  city: "City",
  state: "State",
  country: "Country",
  create_person: "Next",
  personal_data: "Personal Data",
  save: "Save",
  error: {
    user_registration_error: "It wasn't possible to create the user! Please, get in touch with our SAF.",
    delete_user: "This user cannot be deleted!",
  },
  success: {
    att_user_data: "Personal data successfully updated!",
    user_deleted_success: "User successfully deleted!",
    user_deleted_error: "Wasn't possible to delete the user!",
  },
  bpRegisterPopover: "Only for internal BP users",
  bpRegister: "BP Register",
};

const coins: ITranslateCoins = {
  paraguai: {
    coin: "Guaraní",
    country_name: "Paraguay",
  },
  mozambique: {
    coin: "Metical",
    country_name: "Mozambique",
  },
  uruguai: {
    coin: "Uruguayan Peso",
    country_name: "Uruguay",
  },
  swiss: {
    coin: "Swiss Franc",
    country_name: "Swiss",
  },
  europe: {
    coin: "Euro",
    country_name: "Europe",
  },
  argentina: {
    coin: "Argentinian Peso",
    country_name: "Argentina",
  },
  el_salvador: {
    coin: "Dolar",
    country_name: "El Salvador",
  },
  venezuela: {
    coin: "Venezuelan Peso",
    country_name: "Venezuela",
  },
  brazil: {
    coin: "Real",
    country_name: "Brazil",
  },
  moneySymbol: "$",
};

const en: ILanguageMessages = {
  translations: {
    transactions,
    language,
    noInfo,
    userProfile,
    userRegistration,
    login,
    tips,
    creators,
    global,
    dashboard,
    solutions,
    success,
    orders,
    profile,
    sidebar,
    navMenu,
    topbar,
    dropZone,
    fileList,
    goBackBtn,
    optionsBtn,
    orderBox,
    searchBar,
    surgicalPlanning,
    standardSolution,
    customSolution,
    evaluation,
    form_errors,
    disabled_user,
    financial,
    fixiterOnboarding,
    error,
    printer,
    courses,
    address,
    person,
    coins,
    bills,
  },
};

const messages = { en };

export { messages };
