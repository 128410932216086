import { useRoutes } from "react-router-dom";
import BracingTypes from "../../modules/Employee/pages/BracingTypes";
import { CreateOrder } from "../../modules/Orders/CreateOrder";
import Dashboard from "../../modules/Employee/pages/Dashboard";
import Layout from "../../components/Layout";
import { Orders } from "../../modules/Orders";
import { SolutionsCategory } from "../../modules/Employee/pages/SolutionsCategory";
import Solutions from "../../modules/Orders/Solutions";
import Evaluation from "../../modules/licensees/pages/Evaluation";
import Address from "../../modules/Employee/pages/Address";

function EmployeeRoutes() {
  const employeeRoutes = useRoutes([
    {
      path: "/employee/dashboard",
      element: (
        <Layout>
          <Dashboard />
        </Layout>
      ),
    },
    {
      path: "/employee/solutions",
      element: (
        <Layout>
          <SolutionsCategory />
        </Layout>
      ),
    },
    {
      path: "/employee/dashboard",
      element: (
        <Layout>
          <Dashboard />
        </Layout>
      ),
    },
    {
      path: "/employee/categories/:field_key",
      element: (
        <Layout>
          <BracingTypes />
        </Layout>
      ),
    },
    {
      path: "/employee/solutions/:category_key",
      element: (
        <Layout>
          <Solutions />
        </Layout>
      ),
    },
    {
      path: "/employee/order/:solution_key/:solution_type",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/employee/order/:solution_key/:solution_type/:size",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/employee/orders",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/employee/evaluation/:order_id",
      element: (
        <Layout>
          <Evaluation />
        </Layout>
      ),
    },
    {
      path: "/employee/address",
      element: (
        <Layout>
          <Address />
        </Layout>
      ),
    },
  ]);

  return employeeRoutes;
}

export default EmployeeRoutes;
