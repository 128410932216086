import { useEffect, useState } from "react";
import validateFormFields from "../../utils/validateFormFields";

import { i18n } from "../../localization/i18n";
import * as Yup from "yup";
import { OutlinedInput } from "../OutlinedInput";
import { OutlinedSelect } from "../OutlinedSelect";
import { showToast } from "../CustomToast";
import { OutlinedButton } from "../OutlinedButton";
import { SolidButton } from "../SolidButton";
import { OutlinedCheckbox } from "../OutlinedCheckbox";

import { countries } from "../../utils/countries";
import {
  addressTypes,
  brazilianStates,
} from "../../utils/constants";

import api from "../../services/api";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  Text,
  InputNear,
  CheckBoxGroupContainer,
} from "./styles";
import { SearchableSelect } from "../SearchableSelect";
import { AddressTypes } from "./mocks";
import { IAddress, IFormData, Licensee } from "./dtos";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";

interface AddressInfosProps {
  licensee: Licensee;
  editAddress?: IAddress;
  open?: boolean;
  isEditOpen?: boolean;
  address_id?: string;
  readOnly?: boolean;
  closeModal: () => void;
  getLicenseeAddresses?: (id: string) => void;
}

export function AddressModal({
  address_id,
  licensee,
  isEditOpen,
  editAddress,
  readOnly,
  open,
  closeModal,
  getLicenseeAddresses = () => { },
}: AddressInfosProps) {
  const [billingAddress, setBillingAddress] = useState(false);
  const [deliverAddress, setDeliverAddress] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const toggleBillingAddress = () => {
    setBillingAddress(!billingAddress);
  };

  const toggleDeliverAddress = () => {
    setDeliverAddress(!deliverAddress);
  };

  const formattedAdressTypes = AddressTypes.map((address) => ({
    optionText: address.optionText,
    value: address.value,
  }));

  const formattedCountries = countries.map((country) => ({
    label: country.label,
    id: country.code,
  }));

  const [data, setData] = useState<IFormData>({
    type: formattedAdressTypes.find((t) => t.value === addressTypes.home) ?? {
      optionText: "",
      value: "",
    },
    complement: "",
    country: {
      id: "",
      label: "",
    },
    cep: "",
    district: "",
    address: "",
    number: "",
    state: "",
    city: "",
    billing_address: false,
    deliver_address: false,
  });

  const [country, setCountry] = useState("");

  useEffect(() => {
    const editCountry = formattedCountries.find(
      (p) => p.id === licensee.country
    );

    editCountry && setCountry(editCountry.label);

    countries.map((item) => {
      if (item.code === licensee.country) {
        setData(() => ({
          ...data,
          city: licensee.city,
          state: licensee.state,
          country: {
            id: editCountry ? editCountry?.id : "",
            label: editCountry ? editCountry?.label : "",
          },
        }));
      }
    });

    getLicenseeAddresses(licensee.id);
  }, [licensee]);

  useEffect(() => {
    if (isEditOpen && editAddress) {
      setBillingAddress(editAddress.billing_address);
      setDeliverAddress(editAddress.deliver_address);

      const editCountry = formattedCountries.find(
        (p) => p.id === editAddress.country
      );

      editCountry && setCountry(editCountry.label);

      setData({
        ...data,
        type: formattedAdressTypes.find(
          (t) => t.value === editAddress.type
        ) ?? {
          optionText: "",
          value: "",
        },
        address: editAddress.address,
        number: editAddress.number,
        cep: editAddress.cep,
        complement: editAddress.complement,
        district: editAddress.district,
        city: editAddress.city,
        state: editAddress.state,
        country: formattedCountries.find(
          (t) => t.id === editAddress.country
        ) ?? {
          label: "",
          id: "",
        },
      });
    }
  }, [isEditOpen, editAddress]);

  async function handleUpdateAddress(address_id: string) {
    try {
      const body = {
        licensee_id: licensee.id,
        type: data.type.value,
        address: data.address,
        number: data.number,
        cep: data.cep,
        complement: data.complement,
        district: data.district,
        city: data.city,
        state: data.state,
        country: data.country.id,
        billing_address: billingAddress,
        deliver_address: deliverAddress,
      };

      await api.put(`/address/${address_id}`, body);
      showToast({
        type: "success",
        message: "Endereço atualizado com sucesso!",
      });

      getLicenseeAddresses(licensee.id);
      closeModal();
      clearDataState();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreateAddress() {
    try {
      const body = {
        ...data,
        licensee_id: licensee.id,
        type: data.type.value,
        address: data.address,
        number: data.number,
        cep: data.cep,
        complement: data.complement,
        district: data.district,
        city: data.city,
        state: data.state,
        country: data.country.id,
        billing_address: billingAddress,
        deliver_address: deliverAddress,
      };

      const yup_validation = Yup.object().shape({
        type: Yup.string().required(typesErrors.required),
        address: Yup.string().required(typesErrors.required),
        number: Yup.string().required(typesErrors.required),
        cep: Yup.string().required(typesErrors.required),
        district: Yup.string().required(typesErrors.required),
        state: Yup.string().required(typesErrors.required),
        country: Yup.string().required(typesErrors.required),
      });

      const validation = await validateFormFields(body, yup_validation, {
        type: "",
        complement: "",
        cep: "",
        district: "",
        address: "",
        number: "",
        state: "",
        city: "",
        country: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        return;
      }

      setErrors({});
      await api.post(`/address`, body);

      showToast({
        type: "success",
        message: "Endereço cadastrado com sucesso.",
      });

      closeModal();
      clearDataState();
    } catch (err: any) {
      showToast({
        type: "error",
        message:
          "Não foi possível cadastrar o endereço. Entre em contato com o SAF.",
      });
    }
  }

  function clearDataState() {
    setData({
      type: formattedAdressTypes.find((t) => t.value === addressTypes.home) ?? {
        optionText: "",
        value: "",
      },
      complement: "",
      country: {
        label: "",
      },
      cep: "",
      district: "",
      address: "",
      number: "",
      state: "",
      city: "",
    });

    setBillingAddress(false);
    setDeliverAddress(false);
  }

  return (
    <Container>
      <Header>
        <span>
          {readOnly
            ? `${i18n.t("address.address_options.address")}`
            : isEditOpen
              ? `${i18n.t("address.address_options.edit")}`
              : `${i18n.t("address.address_options.new_address")}`}
        </span>
      </Header>

      <FormContainer>
        <InputNear>
          <Text>
            {readOnly
              ? `${i18n.t("address.address_options.created_as")}`
              : `${i18n.t("address.address_options.create_as")}`}
          </Text>
          <OutlinedSelect
            readOnly={readOnly}
            inputWidth={125}
            label={""}
            options={formattedAdressTypes}
            handleSelect={(selected) => {
              setData((prevState) => ({
                ...prevState,
                type: selected,
              }));
            }}
            selectedOption={data?.type?.optionText || ""}
            error={errors?.type}
          />
        </InputNear>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.address")}`}
              inputName="logradouro"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  address: event,
                }))
              }
              value={data.address || ""}
              error={errors?.address}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.number")}`}
              inputName="number"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  number: event,
                }))
              }
              value={data.number || ""}
              error={errors?.number}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.cep")}`}
              inputName="cep"
              handleChange={(event: any) =>
                setData((prevState) => ({
                  ...prevState,
                  cep: event,
                }))
              }
              value={data.cep || ""}
              mask={"99.999-999"}
              error={errors?.cep}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.complement")}`}
              inputName="complement"
              handleChange={(event: any) =>
                setData((prevState) => ({
                  ...prevState,
                  complement: event,
                }))
              }
              value={data.complement || ""}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.district")}`}
              inputName="district"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  district: event,
                }))
              }
              value={data.district || ""}
              error={errors?.district}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedInput
              readOnly={readOnly}
              label={`${i18n.t("address.city")}`}
              inputName="city"
              handleChange={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  city: event,
                }))
              }
              value={data?.city}
              defaultValue={licensee.city || ""}
              error={errors?.city}
            />
          </InputContainer>

          <InputContainer>
            <OutlinedSelect
              readOnly={readOnly}
              error={errors?.state}
              options={brazilianStates}
              label={`${i18n.t("address.state")}`}
              handleSelect={(event) =>
                setData((prevState) => ({
                  ...prevState,
                  state: event.value.toString(),
                }))
              }
              selectedOption={data.state ?? ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <SearchableSelect
              label={`${i18n.t("userRegistration.country")}`}
              options={formattedCountries}
              onChange={(value: any) => setCountry(value)}
              onSelect={(selected) => {
                if (typeof selected === "string" || !selected?.id) {
                  setData((prevState: IFormData) => ({
                    ...prevState,
                    country: {
                      id: "",
                      label: "",
                    },
                  }));

                  return;
                }
                setData((prevState) => ({
                  ...prevState,
                  country: selected,
                }));
              }}
              defaultValue={country}
              error={errors?.country}
              readOnly={readOnly}
            />
          </InputContainer>
        </InputLine>
        <InputLine>
          <CheckBoxGroupContainer>
            <OutlinedCheckbox
              disabled={readOnly}
              label={`${i18n.t("address.billing_address")}`}
              checked={billingAddress}
              handleChange={() => {
                toggleBillingAddress();
              }}
            ></OutlinedCheckbox>

            <OutlinedCheckbox
              disabled={readOnly}
              label={`${i18n.t("address.deliver_address")}`}
              checked={deliverAddress}
              handleChange={() => {
                toggleDeliverAddress();
              }}
            ></OutlinedCheckbox>
          </CheckBoxGroupContainer>
        </InputLine>
      </FormContainer>

      <Footer>
        {readOnly ? (
          <OutlinedButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.close")}`}
            onClick={() => {
              clearDataState();
              closeModal();
            }}
          />
        ) : (
          <OutlinedButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.cancel")}`}
            onClick={() => {
              clearDataState();
              closeModal();
            }}
          />
        )}
        {readOnly ? null : isEditOpen ? (
          <SolidButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.att")}`}
            onClick={() => {
              address_id && handleUpdateAddress(address_id);
              getLicenseeAddresses(licensee.id);
              closeModal();
            }}
          />
        ) : (
          <SolidButton
            buttonWidth={200}
            text={`${i18n.t("address.address_options.save")}`}
            onClick={async () => {
              await handleCreateAddress();
              getLicenseeAddresses(licensee.id);
            }}
          />
        )}
      </Footer>
    </Container>
  );
}
