import { Container } from "./styles";
import {
  RiCheckDoubleFill,
  RiCheckLine,
  RiCloseFill,
  RiEditLine,
  RiEyeLine,
  RiSaveLine,
} from "react-icons/ri";
import {
  AccordionActionsContainer,
  AccordionContainer,
  AccordionContentContainer,
  CheckboxContainer,
  CircleButton,
  HelperInputLine,
  InputContainer,
  InputHelper,
  InputLine,
  LinkButton,
  MeasuresInputLine,
  SectionFormTitle,
  SelectContainer,
} from "../../styles";
import { AccordionOrder } from "../../../../../components/AccordionOrder";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { CustomSolutionForm, IExamData, ISearchData } from "../../dtos";
import ExamImg from "../../../../../assets/order_steps/evaluation.svg";
import { FaSearchPlus } from "react-icons/fa";
import { useCallback, useEffect, useState } from "react";
import { Checkbox } from "../../../../../components/Checkbox";
import { OutlinedSelect } from "../../../../../components/OutlinedSelect";
import { jobs } from "../../mockOptions";
import { i18n } from "../../../../../localization/i18n";
import { AxiosResponse } from "axios";
import api from "../../../../../services/api";
import { showToast } from "../../../../../components/CustomToast";
import { MultipleSearchableSelect } from "../../../../../components/MultipleSearchableSelect";
import { getActiveLanguage } from "../../../../../utils/getActiveLanguage";
import { Box } from "@mui/material";
import { CreateDiagnosisModal } from "./CreateDiagnosisModal";
import * as _ from "lodash";

interface ExamOrderProps {
  sides: string[];
  orderType: string;
  examAccordion: boolean;
  examEdited: Date;
  examComplete: boolean;
  examEditable: boolean;
  examSearchable: boolean;
  tmpExamData: IExamData;
  examFormErrors: any;
  setTmpExamData: (value: React.SetStateAction<IExamData>) => void;
  validateExamForm: (data: IExamData) => Promise<boolean>;
  setExamEdited: (value: React.SetStateAction<Date>) => void;
  setExamAccordion: (value: React.SetStateAction<boolean>) => void;
  setExamEditable: (value: React.SetStateAction<boolean>) => void;
  setExamSearchable: (value: React.SetStateAction<boolean>) => void;
  setExamComplete: (value: React.SetStateAction<boolean>) => void;
  orderData: CustomSolutionForm;
  setOrderData: (value: React.SetStateAction<CustomSolutionForm>) => void;
  handleDiscard?: () => void;
  link_guide: string;
}

export default function ExamOrderSection({
  orderType,
  sides,
  examAccordion,
  examEdited,
  examComplete,
  examEditable,
  examSearchable,
  examFormErrors,
  setExamAccordion,
  setExamSearchable,
  setExamEditable,
  setExamEdited,
  setExamComplete,
  validateExamForm,
  tmpExamData,
  setTmpExamData,
  orderData,
  setOrderData,
  handleDiscard,
  link_guide,
}: ExamOrderProps) {
  const [loading, setLoading] = useState(false);
  const [diagnoses, setDiagnoses] = useState<ISearchData[]>([]);
  const [createDiagnosisModal, setCreateDiagnosisModal] = useState(false);
  const [diagnosisSearch, setDiagnosisSearch] = useState("");

  async function updateExamData() {
    setLoading(true);
    const abortController = new AbortController();
    let signal = abortController.signal;

    const body = {
      ...tmpExamData,
      diagnosis_list: tmpExamData?.diagnosis?.map((item) => {
        return {
          id: item.id,
        };
      }),
      diagnosis: tmpExamData?.other_diagnosis,
      measures: tmpExamData.measures?.map((item) => item?.replace(",", ".")),
      professional_job: tmpExamData.professional_job.value,
      side: tmpExamData.side.value,
    };

    try {
      let response: AxiosResponse;
      if (tmpExamData.id) {
        response = await api.put(`/exams/${tmpExamData.id}`, body, {
          signal,
        });
      } else {
        response = await api.post("/exams", body, { signal });
        setTmpExamData((prevState) => ({
          ...prevState,
          id: response.data.id,
        }));
      }
      setOrderData((prevState) => ({
        ...prevState,
        exam: {
          ...response.data,
          professional_job: tmpExamData.professional_job,
          side: tmpExamData.side,
        },
      }));

      setExamEdited(new Date());
      setExamEditable(false);
      setExamSearchable(false);
      setExamAccordion(false);
      setExamComplete(true);
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível salvar os dados da avaliação",
      });
    } finally {
      setLoading(false);
    }

    return () => abortController.abort();
  }

  async function getDiagnoses(search: string) {
    try {
      const activeLanguage = getActiveLanguage();

      const params = { language: activeLanguage, multi: search };
      const response = await api.get("/diagnosis", { params });

      const formattedDiagnoses = response.data.map((item: any) => {
        return {
          label: `${item.name[activeLanguage]} - ${item.cid}`,
          description: item?.description[activeLanguage],
          verified: item.verified,
          id: item.id,
        };
      });

      setDiagnoses(formattedDiagnoses);

      return formattedDiagnoses;
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível carregar os diagnósticos",
      });
    }
  }

  function changeExamDiagnosis(diagnosis: any) {
    setTmpExamData((prevState) => ({
      ...prevState,
      diagnosis: [...prevState.diagnosis, diagnosis],
    }));
  }

  const debounceDiagnosis = useCallback(
    _.debounce((search) => getDiagnoses(search), 500),
    []
  );

  useEffect(() => {
    getDiagnoses("");
  }, []);

  function getExamSubtitle() {
    if (orderData.exam.professional_name && orderData.exam.professional_job) {
      return `${orderData.exam.professional_name}, ${orderData.exam.professional_job.optionText}`;
    }

    return "";
  }

  return (
    <Container>
      <CreateDiagnosisModal
        open={createDiagnosisModal}
        getDiagnosis={getDiagnoses}
        changeExamDiagnosis={changeExamDiagnosis}
        handleCloseModal={() => setCreateDiagnosisModal(false)}
      />

      <AccordionContainer>
        <AccordionOrder
          open={examAccordion}
          emptyTitle={i18n.t("orders.exam_section.empty_title")}
          emptySubtitle={i18n.t("orders.exam_section.empty_subtitle")}
          title={orderData?.exam.professional_name}
          openDividerText={i18n.t("orders.solution_section.measurement_guide")}
          openDividerLink={link_guide}
          subTitle={getExamSubtitle()}
          icon={ExamImg}
          lastUpdate={examEdited}
          pendingInfos={!examComplete}
          handleDiscard={handleDiscard}
          handleChange={() => {}}
          toogleComponent={
            <AccordionActionsContainer>
              {!examAccordion ? (
                orderData?.exam?.id ? (
                  <CircleButton
                    onClick={() => {
                      setExamAccordion(true);
                    }}
                  >
                    <RiEyeLine size={20} color="#989898" />
                  </CircleButton>
                ) : (
                  <LinkButton
                    onClick={() => {
                      setExamAccordion(true);
                      setExamSearchable(true);
                    }}
                  >
                    {`${i18n.t("orders.actions.new")}`}
                    <FaSearchPlus size={20} color="#989898" />
                  </LinkButton>
                )
              ) : (
                <>
                  {!examEditable && !examSearchable ? (
                    <>
                      <LinkButton onClick={() => setExamEditable(true)}>
                        {`${i18n.t("orders.actions.edit")}`}
                        <RiEditLine size={20} color="#989898" />
                      </LinkButton>
                      <CircleButton>
                        <RiCheckDoubleFill
                          size={25}
                          color="#989898"
                          onClick={() => {
                            setExamAccordion(false);
                            setExamEditable(false);
                            setExamSearchable(false);
                          }}
                        />
                      </CircleButton>
                    </>
                  ) : (
                    <>
                      <LinkButton
                        onClick={() => {
                          validateExamForm(tmpExamData).then((valid) => {
                            valid && updateExamData();
                          });
                        }}
                      >
                        {`${i18n.t("orders.actions.save")}`}
                        <RiSaveLine size={20} color="#989898" />
                      </LinkButton>
                      <CircleButton>
                        <RiCloseFill
                          size={25}
                          color="#989898"
                          onClick={() => {
                            setTmpExamData({ ...orderData.exam });
                            setExamAccordion(false);
                            setExamEditable(false);
                            setExamSearchable(false);
                          }}
                        />
                      </CircleButton>
                    </>
                  )}
                </>
              )}
            </AccordionActionsContainer>
          }
        >
          <AccordionContentContainer>
            <SectionFormTitle>
              {`${i18n.t("orders.exam_section.professional_label")}`}
            </SectionFormTitle>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="professional_name"
                  value={tmpExamData.professional_name}
                  label={i18n.t("customSolution.fullName")}
                  readOnly={!examEditable && !examSearchable}
                  autoComplete="off"
                  handleChange={(event) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      professional_name: event,
                    }));
                  }}
                  error={examFormErrors.professional_name}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer containerWidth="280px">
                <OutlinedInput
                  inputName="partner_contact"
                  value={tmpExamData.phone}
                  label={i18n.t("customSolution.contact")}
                  readOnly={!examEditable && !examSearchable}
                  mask="(99) 99999-9999"
                  handleChange={(event) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      phone: event,
                    }));
                  }}
                  error={examFormErrors.phone}
                />
              </InputContainer>

              <SelectContainer>
                <OutlinedSelect
                  options={jobs}
                  label={i18n.t("userRegistration.expertise")}
                  readOnly={!examEditable && !examSearchable}
                  handleSelect={(selected) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      professional_job: {
                        optionText: selected.optionText,
                        value: selected.value.toString(),
                      },
                    }));
                  }}
                  selectedOption={tmpExamData.professional_job.optionText}
                  error={examFormErrors.professional_job}
                />
              </SelectContainer>
            </InputLine>

            <SectionFormTitle>{`${i18n.t(
              "evaluation.evaluation"
            )}`}</SectionFormTitle>

            <HelperInputLine>
              <InputContainer>
                <MultipleSearchableSelect
                  label={i18n.t("customSolution.diagnosis")}
                  options={diagnoses}
                  defaultValue={diagnosisSearch}
                  readOnly={!examEditable && !examSearchable}
                  value={tmpExamData?.diagnosis}
                  onChange={(event) => {
                    setDiagnosisSearch(event);
                    debounceDiagnosis(event);
                  }}
                  onSelect={(selected) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      diagnosis: [...selected] as ISearchData[],
                    }));
                  }}
                  onBlur={() => setDiagnosisSearch("")}
                  filterOptions={(x) => x}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start !important"
                      sx={{
                        ".diagnosis_desc": {
                          color: "var(--dark-gray)",
                          fontSize: "14px",
                        },
                        svg: {
                          marginLeft: "4px",
                        },
                      }}
                    >
                      <p>
                        {option.label}
                        {option?.verified && (
                          <RiCheckLine color="var(--finished)" />
                        )}
                      </p>
                      <p className="diagnosis_desc">{option?.description}</p>
                    </Box>
                  )}
                  error={examFormErrors.diagnosis}
                />

                <InputHelper>
                  {`${i18n.t("customSolution.diagnostic.newDiagnosisLabel")}`}{" "}
                  <span onClick={() => setCreateDiagnosisModal(true)}>
                    {`${i18n.t(
                      "customSolution.diagnostic.diagnosisRegisters"
                    )}`}
                  </span>
                </InputHelper>
              </InputContainer>
            </HelperInputLine>

            <InputLine>
              <CheckboxContainer>
                <Checkbox
                  readOnly={!examEditable && !examSearchable}
                  checked={tmpExamData.prior_use}
                  handleChange={() => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      prior_use: !prevState.prior_use,
                    }));
                  }}
                />
              </CheckboxContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="previous_prior_orthosis"
                  value={tmpExamData.prior_orthosis}
                  autoComplete="off"
                  label={i18n.t("customSolution.previousOrthesis")}
                  readOnly={!examEditable && !examSearchable}
                  disabled={!tmpExamData.prior_use}
                  handleChange={(event) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      prior_orthosis: event,
                    }));
                  }}
                />
              </InputContainer>

              <SelectContainer containerWidth="200px">
                <OutlinedSelect
                  options={sides?.map((side) => ({
                    value: side,
                    optionText: i18n.t(`global.sides.${side}`),
                  }))}
                  label={i18n.t("customSolution.limb")}
                  readOnly={!examEditable && !examSearchable}
                  handleSelect={(selected) => {
                    setTmpExamData((prevState) => ({
                      ...prevState,
                      side: {
                        optionText: selected.optionText,
                        value: selected.value.toString(),
                      },
                    }));
                  }}
                  selectedOption={tmpExamData.side.optionText}
                  error={examFormErrors.side}
                />
              </SelectContainer>
            </InputLine>
            {tmpExamData.measures?.length && (
              <>
                <SectionFormTitle>{`${i18n.t(
                  "customSolution.measures"
                )}`}</SectionFormTitle>
                <MeasuresInputLine>
                  {tmpExamData.measures?.map((item, index) => (
                    <InputContainer key={index} containerWidth="147px">
                      <OutlinedInput
                        inputName={`measure${index + 1}`}
                        readOnly={!examEditable && !examSearchable}
                        value={item}
                        autoComplete="off"
                        label={`${i18n.t("evaluation.measure")} ${index + 1}`}
                        handleChange={(event) => {
                          setTmpExamData((prevState) => ({
                            ...prevState,
                            measures: tmpExamData.measures?.map((m, i) => {
                              if (i == index) {
                                return event;
                              }
                              return m;
                            }),
                          }));
                        }}
                        error={examFormErrors?.measures[index]}
                      />
                    </InputContainer>
                  ))}
                </MeasuresInputLine>
              </>
            )}
          </AccordionContentContainer>
        </AccordionOrder>
      </AccordionContainer>
    </Container>
  );
}
