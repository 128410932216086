import styled from "styled-components";

type CheckboxContainerProps = {
  checked?: boolean;
  error?: boolean;
  marginOnError?: boolean;
  disabled?: boolean;
  streched?: boolean;
  activeBgColor: string;
  inactiveBgColor: string;
};

export const Container = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  border: 1px solid ${({ checked, activeBgColor }) => checked ? activeBgColor : "var(--gray)"};
  border-radius: 10px;
  padding: 5px;
  background: ${({ checked, activeBgColor, inactiveBgColor }) => checked ? activeBgColor : inactiveBgColor};
  color: ${({ checked }) => checked ? "var(--white)" : "var(--gray)"};
  transition: background-color .5s;
  
  width: ${({ streched }) => streched ? "100%" : "auto" };

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;
    color: ${({ checked }) => checked ? "var(--white)" : "var(--gray)"};
  }
`;

export const Error = styled.div`
  position: absolute;
  bottom: -17px;
  left: 11px;

  font-size: 12px;
  color: var(--formError);
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
`

export const ExtraInfo = styled.span`
  font-size: 0.6em !important;
`