import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "../components/Loading";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { i18n } from "../localization/i18n";
import { services } from "./authService";
import { ISignature, useOnboard } from "./OnboardContext";
import { roles } from "../utils/constants";
import axios from "axios";

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IValidateCode extends ISignInCredentials {
  code: string;
}

interface Licensee {
  language: string;
  id: string;
  contract: string;
  user_term: ITermosUso;
  is_blocked: boolean;
  completed_onboard: boolean;
  situation: string;
  country: string;
  signature: ISignature[];
  state: string;
  phone: string;
  name: string;
  companyName: string;
  manager_name: string;
  document_number: string;
  printers: IPrinter[];
  is_fixpoint: boolean;
  bills: Bill[];
  provider: Licensee
}

export interface Bill {
  id: string;
  type: string;
  bill_value: string;
  referring_period: string;
  due_date: Date;
  payment_date?: Date;
  is_recurrence: boolean;
  licensee_id: string;
  card_id?: string;
  address_id?: string;
  payment_method: string;
  currency: string;
  paid_ammount: string;
  payment_situation:
  | "cancelado"
  | "aguardando"
  | "pago"
  | "vencido"
  | "dispute"
  | "estorno_parcial"
  | "estorno"
  | "chargeback"
  | "credito"
  | string;
  transaction_code: string;
  licensee: Licensee;
}
interface IPrinter {
  name: string;
}

interface Person {
  id: string;
  name: string;
  licensee: Licensee;
  cpf: string;
  country: string;
  state: string;
  city: string;
  cellphone: string;
  expertise: string;
  professional_number: string;
  termsAccepted: boolean;
}

interface IUserData {
  name: string;
  email: string;
  role: string;
  id: string;
  person: Person;
  profile_picture_link: string;
}

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

interface IAuthContextState {
  user: IUserData;
  reloadUser: () => Promise<void>;
  signIn(credentials: ISignInCredentials): Promise<any>;
  signOut(): void;
  validateCode(validation: IValidateCode): Promise<IUserData>;
  navigationAction?: INavigationAction;
  setNavigationAction: Dispatch<SetStateAction<INavigationAction | undefined>>;
  licenseeBills: Bill[];
  licenseeFixcoins: number;
  currency: ICurrencyInfos | undefined;
}

interface INavigationAction {
  hasButton: boolean;
  description: string;
  action?: string;
  link?: string;
}

interface IAuthState {
  loading: boolean;
  token: string;
  user: IUserData;
  refresh_token: string;
}

export interface ICurrencyInfos {
  code: string;
  country: string;
  name: string;
  symbol: string;
  custom_tax: number;
  urgency_tax: number;
  is_default: boolean;
}

export interface IUserDeviceInfos {
  id?: string;
  ip: string;
  city: string;
  state: string;
  country: string;
  latitude: string;
  longitude: string;
  timezone: string;
  user_agent: string;
  screen_resolution: string;
  allowed_to_print: boolean;
}

const AuthContext = createContext<IAuthContextState>({} as IAuthContextState);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState<IAuthState>({ loading: true } as IAuthState);
  const [navigationAction, setNavigationAction] = useState<INavigationAction>();
  const [licenseeBills, setLicenseeBills] = useState<Bill[]>([]);
  const [licenseeFixcoins, setLicenseeFixcoins] = useState<number>(0);
  const [currency, setCurrency] = useState<ICurrencyInfos>();
  const { planHasExpired } = useOnboard();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // useEffect(() => {
  //   if(data?.user?.id) {
  //     navigate('')
  //   }
  // }, [data?.user?.id])

  const verifyUser = (token: any, refresh_token: any) => {
    api
      .get("/users/me")
      .then((response) => {
        const user = response.data;
        setData((prevState) => ({
          ...prevState,
          token,
          user: user,
          refresh_token,
          loading: false,
        }));
        getAndRegisterUserDevice();
      })
      .catch((err) => {
        signOut();
      });
  };

  async function getUserCurrencyInfo() {
    try {
      await api.get(`/currencies`).then((response) => {
        setCurrency(response.data);
      });
    } catch (err) {
      console.log("Error getting user currency");
    }
  }

  const reloadUser = async () => {
    api
      .get("/users/me")
      .then((response) => {
        const user = response.data;
        setData((prevState) => ({ ...prevState, user: user, loading: false }));
      })
      .catch((err) => { });
  };

  useEffect(() => {
    const token = localStorage.getItem("@FixitApp:token");
    const refresh_token = localStorage.getItem("@FixitApp:refresh_token");

    // const user = localStorage.getItem("@FixitApp:user");
    // This code is temporary. Its goal is to remove user data from browser starage.
    /* if (user) {
      localStorage.removeItem("@FixitApp:user");
    } */

    verifyLanguage();

    if (!token || !refresh_token) {
      localStorage.removeItem("@FixitApp:token");
      localStorage.removeItem("@FixitApp:refresh_token");
      setData({ loading: false } as IAuthState);
      return;
    }
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    api
      .get("/users/me")
      .then((response) => {
        const user = response.data;
        setData((prevState) => ({
          ...prevState,
          token,
          user: user,
          refresh_token,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        signOut();
      });

  }, []);

  async function fetchIP() {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      return response.data;
    } catch (error) {
      console.error("Falha em obter endereço IP", error)
    }
  }

  async function getAndRegisterUserDevice() {

    await fetchIP().then(async (data) => {

      const body: IUserDeviceInfos = {
        id: localStorage.getItem("@FixitApp:Device") ?? undefined,
        ip: data.IPv4,
        country: data.country_code,
        city: data.city,
        state: data.state,
        latitude: data.latitude,
        longitude: data.longitude,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        user_agent: navigator.userAgent,
        screen_resolution: `${window.screen.width}x${window.screen.height}`,
        allowed_to_print: true
      }

      try {

        await api.post("/user-devices/register", body).then((response) => {
          localStorage.setItem("@FixitApp:Device", response.data.id)
        })
      } catch (error) {
        console.error(error)
      }

    })
  }

  const signIn = useCallback(
    async ({ email, password }: ISignInCredentials) => {
      const response = await api.post("/sessions", {
        email,
        password,
      });

      const { token, user, refresh_token } = response.data;

      localStorage.getItem("@FixitApp:baudRate") === undefined &&
        localStorage.setItem("@FixitApp:baudRate", "115200");

      localStorage.setItem("@FixitApp:token", token);
      localStorage.setItem("@FixitApp:refresh_token", refresh_token);

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      verifyUser(token, refresh_token);
      setData({ token, user, refresh_token, loading: false });

      const redirectLink = await services.getUserRoleAndRedirect(
        pathname,
        user,
        user.role,
        planHasExpired
      );

      navigate(`${redirectLink}`);
    },
    []
  );

  const validateCode = useCallback(
    async ({ email, code, password }: IValidateCode) => {
      const response = await api.post("/users/validate-user", {
        email,
        code,
        password,
      });

      const { token, user, refresh_token } = response.data;

      localStorage.setItem("@FixitApp:token", token);
      localStorage.setItem("@FixitApp:refresh_token", refresh_token);

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setData({ token, user, refresh_token, loading: false });
      return user;
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("@FixitApp:token");
    localStorage.removeItem("@FixitApp:refresh_token");

    delete api.defaults.headers.common["Authorization"];

    setData({ loading: false } as IAuthState);
    navigate("/");
  }, [navigate]);

  const verifyLanguage = useCallback(() => {
    const languageAlreadySelected = localStorage.getItem("i18nextLng");
    if (languageAlreadySelected) return;

    const browserLanguage = window.navigator.language;
    const spanishLanguage = browserLanguage.startsWith("es");
    const portugueseLanguage = browserLanguage.startsWith("pt");

    if (spanishLanguage) {
      window.localStorage.setItem("i18nextLng", "es");
      i18n.changeLanguage("es");
    } else if (portugueseLanguage) {
      window.localStorage.setItem("i18nextLng", "pt-BR");
      i18n.changeLanguage("pt-BR");
    } else {
      window.localStorage.setItem("i18nextLng", "en");
      i18n.changeLanguage("en");
    }
  }, []);

  async function getLicenseeBills() {
    try {
      await api.get("/bills").then((response) => {
        setLicenseeBills(response.data);
      });
    } catch (error) {
      console.log("There is no bills for this licensee");
    }
  }

  async function getLicenseeFixcoins(id: string) {
    try {
      await api.get(`/transactions/fixcoin/${id}`).then((response) => {
        setLicenseeFixcoins(response.data);
      });
    } catch (error) {
      console.log("Error to fetch licensee fixcoins");
    }
  }

  useEffect(() => {
    if (data.user) {
      (data.user.role === roles.licensee ||
        data.user.role === roles.fixiter ||
        data.user.role === roles.employee) &&
        getUserCurrencyInfo();
      if (data.user.role === roles.licensee && data.user.person !== null) {
        getLicenseeBills();
        getLicenseeFixcoins(data.user.person?.licensee.id);
      }
    }
  }, [data.user]);

  return (
    <>
      <Loading
        loading={data.loading}
        text={`${i18n.t("login.starting")}`}
        numberOfDots={3}
        dotSymbol="."
      />
      {/* Incluir componente mockado para exibir ao fundo do Loading*/}
      {!data.loading && (
        <AuthContext.Provider
          value={{
            user: data.user,
            reloadUser,
            signIn,
            signOut,
            validateCode,
            navigationAction,
            setNavigationAction,
            licenseeBills,
            licenseeFixcoins,
            currency,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};

function useAuth(): IAuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
