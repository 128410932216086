import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/AuthContext";
import { usePrinters } from "../../../hooks/PrintersContext";
import { useOnboard } from "../../../hooks/OnboardContext";

import { i18n } from "../../../localization/i18n";
import * as Yup from "yup";

import { Checkbox } from "../../../components/Checkbox";
import { showToast } from "../../../components/CustomToast";

import { ConfirmOrderModal } from "./components/ConfirmOrderModal";
import PatientOrderSection from "./components/PatientOrderSection";
import PartnerOrderSection from "./components/PartnerOrderSection";
import ExamOrderSection from "./components/ExamOrderSection";
import PlanitOrderSection from "./components/PlanitOrderSection";
import FinalOrderSection from "./components/FinalOrderSection";

import api, { isAxiosCancel } from "../../../services/api";

import { typesErrors } from "../../../utils/validateFormFields/yupErrors";
import { orderTypes, orderUrgencyTypes, roles, solutionTypes } from "../../../utils/constants";
import validateFormFields from "../../../utils/validateFormFields";

import {
  startExamData,
  startPartnerData,
  startPatientData,
  startOrderData,
  orderTypeOptions,
  startPlanitData,
} from "./mockOptions";

import {
  CustomSolutionForm,
  IExamData,
  IPartnerData,
  IPatientData,
  IPlanitData,
  IPrice,
  ISolutionData,
  IStandard,
  Solution,
} from "./dtos";

import { Container, Content, CheckboxContainer, ContentHeader } from "./styles";

export function CreateOrder() {
  const { setNavigationAction, user, currency } = useAuth();
  const { licensee } = useOnboard();
  const { defaultPrinter, userHasPrinter } = usePrinters();
  const navigate = useNavigate();

  const { solution_key, solution_type, size } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [confirmOrderModal, setConfirmOrderModal] = useState(false);
  const [solution, setSolution] = useState<Solution>({} as Solution);
  const [standard, setStandard] = useState<IStandard>();

  // Adicionar type e metricType nas orders
  // vai ficar melhor de controlar e de realizar consultas
  const [orderType, setOrderType] = useState<string>(orderTypes.patient);
  const [metricType, setMetricType] = useState("custom");

  const [planitAccordion, setPlanitAccordion] = useState(false);
  const [planitEditable, setPlanitEditable] = useState(false);
  const [planitSearchable, setPlanitSearchable] = useState(false);

  const [partnerAccordion, setPartnerAccordion] = useState(false);
  const [partnerEditable, setPartnerEditable] = useState(false);
  const [partnerSearchable, setPartnerSearchable] = useState(false);

  const [patientAccordion, setPatientAccordion] = useState(false);
  const [patientEditable, setPatientEditable] = useState(false);
  const [patientSearchable, setPatientSearchable] = useState(false);

  const [examAccordion, setExamAccordion] = useState(false);
  const [examEditable, setExamEditable] = useState(false);
  const [examSearchable, setExamSearchable] = useState(false);

  const [planitEdited, setPlanitEdited] = useState(new Date());
  const [partnerEdited, setPartnerEdited] = useState(new Date());
  const [patientEdited, setPatientEdited] = useState(new Date());
  const [examEdited, setExamEdited] = useState(new Date());

  const [partnerFormErrors, setPartnerFormErrors] = useState({} as any);
  const [patientFormErrors, setPatientFormErrors] = useState({} as any);
  const [examFormErrors, setExamFormErrors] = useState({
    measures: [],
  } as any);
  const [planitFormErrors, setPlanitFormErrors] = useState({} as any);
  const [solutionFormErrors, setSolutionFormErrors] = useState({} as any);

  const [planitComplete, setPlanitComplete] = useState(false);
  const [partnerComplete, setPartnerComplete] = useState(false);
  const [patientComplete, setPatientComplete] = useState(false);
  const [examComplete, setExamComplete] = useState(false);
  const [solutionComplete, setSolutionComplete] = useState(false);

  const [orderData, setOrderData] =
    useState<CustomSolutionForm>(startOrderData);
  const [tmpPlanitData, setTmpPlanitData] =
    useState<IPlanitData>(startPlanitData);
  const [tmpPartnerData, setTmpPartnerData] =
    useState<IPartnerData>(startPartnerData);
  const [tmpPatientData, setTmpPatientData] =
    useState<IPatientData>(startPatientData);
  const [tmpExamData, setTmpExamData] = useState<IExamData>(startExamData);
  const [tmpSolutionData, setTmpSolutionData] = useState<ISolutionData>({
    color: {
      optionText: "",
      value: "",
    },
    notes: "",
  });

  const [filePrice, setFilePrice] = useState<IPrice>();

  useEffect(() => {
    if (!filePrice) {
      setSolutionComplete(false);
    }
  }, [filePrice]);

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (!solution?.id) {
      setFilePrice(undefined);
      return;
    }

    api
      .get(`/prices/calc/${solution.id}`, { signal })
      .then(({ data }) => setFilePrice(data))
      .catch((err) => {
        if (isAxiosCancel(err)) {
          return "axios request cancelled";
        }
        setFilePrice(undefined);
      });

    return () => abortController.abort();
  }, [solution, orderType, metricType]);

  function clearPartner() {
    setTmpPartnerData(startPartnerData);
    setOrderData({ ...orderData, partner: startPartnerData });
    setPartnerComplete(false);
    // const storageOrder = JSON.parse(
    //   localStorage.getItem("currentOrder") || "{}"
    // );
    // localStorage.setItem(
    //   "currentOrder",
    //   JSON.stringify({
    //     ...storageOrder,
    //     partner: undefined,
    //   })
    // );
  }

  function clearPatient() {
    setTmpPatientData(startPatientData);
    setOrderData({ ...orderData, patient: startPatientData });
    setPatientComplete(false);
    // const storageOrder = JSON.parse(
    //   localStorage.getItem("currentOrder") || "{}"
    // );
    // localStorage.setItem(
    //   "currentOrder",
    //   JSON.stringify({
    //     ...storageOrder,
    //     patient: undefined,
    //   })
    // );
  }

  const arrayFromSize = (size: number) => {
    const array: string[] = [];

    for (var i = 0; i < size; i++) {
      array.push("");
    }

    return array;
  };

  function clearExam() {
    const tmpMeasures = arrayFromSize(solution.number_measures);

    setTmpExamData({
      ...startExamData,
      measures: tmpMeasures,
    });
    setOrderData({
      ...orderData,
      exam: {
        ...startExamData,
        measures: tmpMeasures,
      },
    });
    setExamComplete(false);
    // const storageOrder = JSON.parse(
    //   localStorage.getItem("currentOrder") || "{}"
    // );
    // localStorage.setItem(
    //   "currentOrder",
    //   JSON.stringify({
    //     ...storageOrder,
    //     exam: undefined,
    //   })
    // );
  }

  function clearPlanit() {
    setTmpPlanitData(startPlanitData);
    setOrderData({ ...orderData, planit: startPlanitData });
    setPlanitComplete(false);
    // const storageOrder = JSON.parse(
    //   localStorage.getItem("currentOrder") || "{}"
    // );
    // localStorage.setItem(
    //   "currentOrder",
    //   JSON.stringify({
    //     ...storageOrder,
    //     planit: undefined,
    //   })
    // );
  }

  function clearAll() {
    setTmpPartnerData(startPartnerData);
    setTmpPatientData(startPatientData);
    setTmpPlanitData(startPlanitData);
    clearExam();
    setPartnerComplete(false);
    setPatientComplete(false);
    setPlanitComplete(false);
    // localStorage.removeItem("currentOrder");
  }

  //  a duplicação de código tá além
  async function handleSubmit() {
    setLoading(true);

    if (!filePrice || !licensee) {
      return;
    }

    const abortController = new AbortController();
    let signal = abortController.signal;

    const orderTotal = orderData.quantity * filePrice.file_price; // send filePrice only for conference ... can not be divergente

    const body = {
      ...orderData,
      provider: !userHasPrinter ? licensee.provider_id : null,
      notes: tmpSolutionData.notes,
      color: tmpSolutionData.color.value,
      solution_id: solution.id,
      category:
        !userHasPrinter
          ? orderTypes.fixiter
          : orderTypeOptions.find((option) => option.value === orderType)
            ?.value || orderTypes.patient,
      patient:
        (solution_type === "custom" || solution_type === "plan") &&
          orderData.patient?.id
          ? orderData.patient
          : undefined,
      partner: orderData.partner?.id ? { id: orderData.partner.id } : undefined,
      exam:
        solution_type === "custom" && orderData.exam?.id
          ? { id: orderData.exam.id }
          : undefined,
      printer_id:
        user.role === roles.licensee || user.role === roles.employee
          ? defaultPrinter?.value
          : undefined,
      planit:
        solution_type === "plan" && orderData.planit?.id
          ? { id: orderData.planit.id }
          : undefined,
      total:
        orderData.urgencyType === orderUrgencyTypes.requested
          ? orderTotal +
          (Number(tmpPatientData.age) <= 12
            ? 0
            : Number(currency?.custom_tax)) +
          Number(currency?.urgency_tax)
          : orderData.urgencyType === orderUrgencyTypes.normal &&
            (solution_type === "custom" || solution_type === solutionTypes.plan)
            ? orderTotal +
            (Number(tmpPatientData.age) <= 12
              ? 0
              : Number(currency?.custom_tax))
            : orderTotal,
      standard: standard,
      urgency_type: orderData.urgencyType,
      urgency_reason:
        orderData.urgencyType === orderUrgencyTypes.requested
          ? orderData.urgencyReason
          : null,
      solution_type: metricType === "standard" ? metricType : solution_type,
      licensee_id: licensee.id,
      delivery_address: orderData.delivery_address,
    };

    try {
      /*
        Faltando Color, solution id, printer_id,
      */
      await api.post("/orders", body, { signal });

      showToast({
        type: "success",
        message: i18n.t("customSolution.successToast"),
      });

      clearAll();

      user.role === roles.fixiter || user.role === roles.employee
        ? navigate(`/${user.role.toLowerCase()}/orders`)
        : navigate("/orders");
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível salvar pedido",
      });
    } finally {
      setLoading(false);
    }

    return () => abortController.abort();
  }

  async function getSolutionData(signal: AbortSignal) {
    setLoading(true);
    try {
      const response = await api.get(`/solutions/key/${solution_key}`, {
        signal,
      });

      setSolution(response.data);
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os dados da solução.",
      });
    } finally {
      setLoading(false);
    }
  }

  async function validatePlanitForm(data: IPlanitData) {
    const planitSchema = Yup.object().shape({
      doctor_name: Yup.string().required(typesErrors.required),
      doctor_contact: Yup.string().required(typesErrors.required),
      body_part: Yup.string().required(typesErrors.required),
      injury_type: Yup.string().required(typesErrors.required),
      target: Yup.string().required(typesErrors.required),
      surgery_date: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(data, planitSchema, {
      doctor_name: "",
      doctor_contact: "",
      body_part: "",
      injury_type: "",
      target: "",
      surgery_date: "",
    });

    if (validation.status === 400) {
      setPlanitFormErrors(validation.errors);
      setPlanitComplete(false);
      showToast({ type: "error", message: "Verifique os campos com erro" });

      return false;
    }

    setPlanitFormErrors({});
    return true;
  }

  async function validatePartnerForm(data: IPartnerData) {
    const partnerSchema = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      phone: Yup.string().required(typesErrors.required),
      email: Yup.string().required(typesErrors.required),
      professional_number: Yup.string().required(typesErrors.required),
      cep: Yup.string().required(typesErrors.required),
      document_number: Yup.string().required(typesErrors.required),
      company_name: !tmpPartnerData.is_person
        ? Yup.string().required(typesErrors.required)
        : Yup.string().notRequired(),
    });

    const validation = await validateFormFields(data, partnerSchema, {
      name: "",
      phone: "",
      email: "",
      professional_number: "",
      cep: "",
      document_number: "",
      company_name: "",
    });

    if (validation.status === 400) {
      setPartnerFormErrors(validation.errors);
      setPartnerComplete(false);
      showToast({ type: "error", message: "Verifique os campos com erro" });
      return false;
    }

    setPartnerFormErrors({});
    return true;
  }

  async function validatePatientForm(data: IPatientData) {
    const body = {
      ...data,
      gender: data.gender.value,
    };

    const patientSchema = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      // gender: Yup.string().required(typesErrors.required),
      age: Yup.string().required(typesErrors.required),
      weight: Yup.string().required(typesErrors.required),
      height: Yup.string().required(typesErrors.required),
      phone: Yup.string().required(typesErrors.required),
      email: Yup.string().required(typesErrors.required),
      responsible: Yup.string().when('age', {
        is: (age: number) => age < 18 || age === 0,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string(),
      }),
    });

    const validation = await validateFormFields(body, patientSchema, {
      name: "",
      gender: "",
      age: "",
      weight: "",
      height: "",
      phone: "",
      email: "",
      responsible: "",
    });

    if (validation.status === 400) {
      setPatientFormErrors(validation.errors);
      setPatientComplete(false);
      showToast({ type: "error", message: "Verifique os campos com erro" });

      return false;
    }

    setPatientFormErrors({});
    return true;
  }

  async function validateExamForm(data: IExamData) {
    const body = {
      ...data,
      diagnosis:
        data?.other_diagnosis || data.diagnosis.map((item) => item.id)[0],
      professional_job: data.professional_job.value,
      side: data.side.value,
    };

    const examSchema = Yup.object().shape({
      professional_name: Yup.string().required(typesErrors.required),
      phone: Yup.string().required(typesErrors.required),
      diagnosis: Yup.string().required(typesErrors.required),
      professional_job: Yup.string().required(typesErrors.required),
      side: Yup.string().required(typesErrors.required),
      measures:
        metricType === "custom"
          ? Yup.array().of(Yup.string().required(typesErrors.minRequired))
          : Yup.array().notRequired(),
    });

    const validation = await validateFormFields(body, examSchema, {
      professional_name: "",
      phone: "",
      diagnosis: "",
      professional_job: "",
      side: "",
      measures: tmpExamData.measures?.map(() => {
        return "";
      }),
    });

    if (validation.status === 400) {
      setExamFormErrors(validation.errors);
      setExamComplete(false);
      showToast({ type: "error", message: "Verifique os campos com erro" });

      return false;
    }

    setExamFormErrors({
      measures: [],
    });
    return true;
  }

  async function validateSolutionForm() {
    const body = {
      color: tmpSolutionData.color.value,
      printer: defaultPrinter?.value,
    };

    const solutionSchema = Yup.object().shape({
      color:
        metricType === "custom" && solution_type === "custom"
          ? Yup.string().required(typesErrors.required)
          : Yup.string().notRequired(),
      printer:
        userHasPrinter
          ? Yup.string().required(typesErrors.required)
          : Yup.string().notRequired(),
    });

    const validation = await validateFormFields(body, solutionSchema, {
      color: "",
      printer: "",
    });

    if (validation.status === 400) {
      setSolutionFormErrors(validation.errors);
      setSolutionComplete(false);
      showToast({ type: "error", message: "Verifique os campos com erro" });

      return false;
    }

    setSolutionFormErrors({});
    return true;
  }

  function verifySubmit() {
    if (
      (partnerComplete || orderType !== "is_partner") &&
      (patientComplete || solution_type === "standard") &&
      (examComplete || solution_type !== "custom") &&
      (planitComplete || solution_type !== "plan")
    ) {
      return false;
    }

    return true;
  }

  function changeOrderData(data: Partial<CustomSolutionForm>) {
    setOrderData((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: `${i18n.t(`orders.create.${solution_type}.header`)}`,
    });
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    getSolutionData(signal);

    return () => abortController.abort();
  }, [solution_key]);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: `${i18n.t(
        `orders.create.${solution_type}.header_template`
      )} ${solution.name}`,
    });

    const tmpMeasures = arrayFromSize(solution.number_measures);

    setTmpExamData({
      ...startExamData,
      measures: tmpMeasures,
    });

    if (size) {
      setStandard(solution?.standards?.find((s) => s.size === size));
    }
  }, [solution]);

  useEffect(() => {
    if (!userHasPrinter) {
      setOrderType(orderTypes.fixiter);
    }
  }, [userHasPrinter]);

  return (
    <Container>
      <ConfirmOrderModal
        open={confirmOrderModal}
        order={orderData}
        solutionType={solution_type}
        price={filePrice}
        solution={solution}
        onConfirmOrder={handleSubmit}
        handleCloseModal={() => setConfirmOrderModal(false)}
        changeOrder={changeOrderData}
        currency={currency}
        patientInfo={tmpPatientData}
      />

      <Content>
        {userHasPrinter && (
          <ContentHeader>
            <CheckboxContainer>
              <Checkbox
                checked={orderType === orderTypes.patient}
                handleChange={() => setOrderType(orderTypes.patient)}
              />
              <span>{`${i18n.t("orders.order_type.is_patient")}`}</span>
            </CheckboxContainer>

            <CheckboxContainer>
              <Checkbox
                checked={orderType === orderTypes.partner}
                handleChange={() => setOrderType(orderTypes.partner)}
              />
              <span>{`${i18n.t("orders.order_type.is_partner")}`}</span>
            </CheckboxContainer>
          </ContentHeader>
        )}
        <PartnerOrderSection
          orderType={orderType}
          partnerAccordion={partnerAccordion}
          partnerEdited={partnerEdited}
          partnerComplete={partnerComplete}
          partnerEditable={partnerEditable}
          partnerSearchable={partnerSearchable}
          partnerFormErrors={partnerFormErrors}
          setPartnerAccordion={setPartnerAccordion}
          setPartnerSearchable={setPartnerSearchable}
          setPartnerEditable={setPartnerEditable}
          setPartnerEdited={setPartnerEdited}
          setPartnerComplete={setPartnerComplete}
          validatePartnerForm={validatePartnerForm}
          tmpPartnerData={tmpPartnerData}
          setTmpPartnerData={setTmpPartnerData}
          orderData={orderData}
          setOrderData={setOrderData}
          handleDiscard={clearPartner}
        />

        {(solution_type === "custom" || solution_type === "plan") && (
          <PatientOrderSection
            orderType={orderType}
            patientAccordion={patientAccordion}
            patientEdited={patientEdited}
            patientComplete={patientComplete}
            patientEditable={patientEditable}
            patientSearchable={patientSearchable}
            patientFormErrors={patientFormErrors}
            setPatientAccordion={setPatientAccordion}
            setPatientSearchable={setPatientSearchable}
            setPatientEditable={setPatientEditable}
            setPatientEdited={setPatientEdited}
            setPatientComplete={setPatientComplete}
            validatePatientForm={validatePatientForm}
            tmpPatientData={tmpPatientData}
            setTmpPatientData={setTmpPatientData}
            orderData={orderData}
            setOrderData={setOrderData}
            handleDiscard={clearPatient}
          />
        )}

        {solution_type === "plan" && (
          <PlanitOrderSection
            orderType={orderType}
            planitAccordion={planitAccordion}
            planitEdited={planitEdited}
            planitComplete={planitComplete}
            planitEditable={planitEditable}
            planitSearchable={planitSearchable}
            planitFormErrors={planitFormErrors}
            setPlanitAccordion={setPlanitAccordion}
            setPlanitSearchable={setPlanitSearchable}
            setPlanitEditable={setPlanitEditable}
            setPlanitEdited={setPlanitEdited}
            setPlanitComplete={setPlanitComplete}
            validatePlanitForm={validatePlanitForm}
            tmpPlanitData={tmpPlanitData}
            setTmpPlanitData={setTmpPlanitData}
            orderData={orderData}
            setOrderData={setOrderData}
            handleDiscard={clearPlanit}
          />
        )}

        {solution_type === "custom" && !!tmpExamData?.measures && (
          <ExamOrderSection
            orderType={orderType}
            examAccordion={examAccordion}
            examEdited={examEdited}
            examComplete={examComplete}
            examEditable={examEditable}
            examSearchable={examSearchable}
            examFormErrors={examFormErrors}
            setExamAccordion={setExamAccordion}
            setExamSearchable={setExamSearchable}
            setExamEditable={setExamEditable}
            setExamEdited={setExamEdited}
            setExamComplete={setExamComplete}
            validateExamForm={validateExamForm}
            tmpExamData={tmpExamData}
            setTmpExamData={setTmpExamData}
            orderData={orderData}
            setOrderData={setOrderData}
            handleDiscard={clearExam}
            sides={solution.sides}
            link_guide={solution.link_guide}
          />
        )}

        <FinalOrderSection
          solution_type={solution_type}
          metricType={metricType}
          solutionComplete={solutionComplete}
          solution={solution}
          standard={standard}
          tmpSolutionData={tmpSolutionData}
          solutionFormErrors={solutionFormErrors}
          orderData={orderData}
          filePrice={filePrice}
          setMetricType={setMetricType}
          setTmpSolutionData={setTmpSolutionData}
          setStandard={setStandard}
          verifySubmit={verifySubmit}
          setOrderData={setOrderData}
          validateSolutionForm={validateSolutionForm}
          handleSubmit={() => {
            setConfirmOrderModal(true);
          }}
          currency={currency}
        />
      </Content>
    </Container>
  );
}
