import { useState, useRef, useEffect, useContext, createContext } from "react";
import { usePrinters } from "./PrintersContext";


interface PrinterWebSocketContextProps {
  isReady: boolean,
  value: string | null,
  commandsExecuted: bigint,
  setCommandsExecuted: (value: number) => void,
  send: (command: string) => Promise<void>,
  setIPAddress: (ip: string) => void
}

const PrinterWebSocketContext = createContext(
  { isReady: false, value: "", send: (command: string) => { }, setIPAddress: (ip: string) => { } } as PrinterWebSocketContextProps)

const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = useState(false)
  const [value, setValue] = useState<string | null>(null)
  const [commandsExecuted, setCommandsExecuted] = useState<bigint>(0n)

  const [printerIPAddress, setPrinterIPAddress] = useState(localStorage.getItem("@FixitApp:printerIP") || "")

  const ws = useRef<WebSocket | null>(null)

  const { setDefaultPrinter, defaultPrinter } = usePrinters()

  useEffect(() => {
    if (!printerIPAddress) {
      return
    }
    const socket = new WebSocket(`wss://${printerIPAddress}/websocket`)

    socket.onopen = () => {
      setIsReady(true)

      defaultPrinter &&
        setDefaultPrinter(
          {
            ...defaultPrinter,
            ipConnected: true
          }
        )
    }
    socket.onclose = (e) => {
      setIsReady(false)
      defaultPrinter &&
        setDefaultPrinter(
          {
            ...defaultPrinter,
            ipConnected: false
          }
        )
    }
    socket.onmessage = (event) => {
      const webSocketResponse = JSON.parse(event.data)
      setValue(`${webSocketResponse.result}`)
      if (webSocketResponse.result === "ok") {
        setCommandsExecuted((prevState) => prevState + 1n)
      }
    }

    socket.onerror = (event) => {
      console.error("WebSocket error on connection")
    }

    ws.current = socket as WebSocket | null

    return () => {
      if (socket.OPEN) {
        socket.close()
      }
    }

  }, [defaultPrinter, printerIPAddress])

  const contextValues = {
    isReady,
    value,
    commandsExecuted,
    setCommandsExecuted,
    send: (command: string): Promise<void> => {
      if (ws.current) {
        const formattedCommandforRPC =
          JSON.stringify({
            jsonrpc: "2.0",
            method: "printer.gcode.script",
            params: {
              script: `${command}`
            },
            id: 2
          });

        ws.current!.send(formattedCommandforRPC);
      }

      return Promise.resolve();
    },
    setIPAddress: (ip: string) => {
      setPrinterIPAddress(ip)
    }
  }


  return (
    <PrinterWebSocketContext.Provider value={contextValues}>
      {children}
    </PrinterWebSocketContext.Provider>
  )
};

function useWebSocket() {
  const context = useContext(PrinterWebSocketContext);

  if (!context) {
    throw new Error("useWebSocket must be used within an AuthProvider");
  }

  return context;
}

export { useWebSocket, WebSocketProvider };
