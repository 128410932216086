import { useState, useEffect } from "react";

import { useAuth } from "../../../../hooks/AuthContext";
import { useOnboard } from "../../../../hooks/OnboardContext";

import { i18n } from "../../../../localization/i18n";

import CustomToast, { showToast } from "../../../../components/CustomToast";
import { Stepper } from "../../../../components/Stepper";

import { StepOne } from "./Components/StepOne";
import { StepTwo } from "./Components/StepTwo";

import api from "../../../../services/api";

import { countries } from "../../../../utils/countries";
import {
  addressTypes,
  customerSituations,
  personTypes,
} from "../../../../utils/constants";

import {
  IAddressFormData,
  IAuthFormData,
  ILicenseeFormData,
  IPersonFormData,
} from "./dtos.d";

import { getActiveLanguage } from "../../../../utils/getActiveLanguage";

import LogoImg from "../../../../assets/logo.svg";

import {
  Container,
  LogoContainer,
  FormContainer,
  StepperContainer,
  ChangeLanguageContainer,
  LanguageSelect,
  ChangePriceContainer,
  PriceSelect,
} from "./styles";

export interface Plans {
  id: string;
  is_active: boolean;
  link_image: string;
  description: any;
  orders_quantity: string;
  plan_name: string;
  price: string;
  solutions_quantity: number | null;
  trial_period: number | null;
}

export function UserRegistration() {
  const { user, signOut, validateCode } = useAuth();
  const { licensee } = useOnboard();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [validatingEmail, setValidatingEmail] = useState(false);
  const [naturalPerson, setNaturalPerson] = useState(false);
  const [authFormData, setAuthFormData] = useState<IAuthFormData>();
  const [personFormData, setPersonFormData] = useState<IPersonFormData>();
  const [licenseeFormData, setLicenseeFormData] = useState<ILicenseeFormData>();
  const [addressFormData, setAddressFormData] = useState<IAddressFormData>();
  const [userId, setUserId] = useState("");
  const [personId, setPersonId] = useState("");
  const [licenseeId, setLicenseeId] = useState("");
  const [licenseeCountry, setLicenseeCountry] = useState("BRA");
  const [selectedLanguage, setSelectedLanguage] = useState(getActiveLanguage());
  const [selectedPrice, setSelectedPrice] = useState("");

  const steps = [
    {
      label: i18n.t("userRegistration.stepOne"),
      component: (
        <StepOne
          setEmailValidation={setValidatingEmail}
          validatingEmail={validatingEmail}
          formData={authFormData}
          setFormData={setAuthFormData}
          nextStep={nextStep}
          deleteCode={handleDeleteCode}
          prevStep={prevStep}
          loading={loading}
          submitCode={handleSubmitCode}
          validateEmail={handleCreateUser}
          resendCode={resendCode}
        />
      ),
    },
    {
      label: i18n.t("userRegistration.stepTwo"),
      component: (
        <StepTwo
          personFormData={personFormData}
          setPersonFormData={setPersonFormData}
          executeUserCreation={handleCreatePerson}
          licenseeFormData={licenseeFormData}
          setLicenseeFormData={setLicenseeFormData}
          addressFormData={addressFormData}
          setAddressFormData={setAddressFormData}
          loading={loading}
        />
      ),
    },
    // {
    //   label: i18n.t("userRegistration.stepThree"),
    //   component: (
    //     <StepThree
    //       setFormData={setLicenseeFormData}
    //       formData={licenseeFormData}
    //       nextStep={nextStep}
    //       prevStep={prevStep}
    //       createLicensee={handleCreateLicensee}
    //       loading={loading}
    //     />
    //   ),
    // },
    // {
    //   label: i18n.t("userRegistration.stepFour"),
    //   component: (
    //     <StepFour
    //       nextStep={nextStep}
    //       prevStep={prevStep}
    //       plans={plans}
    //       moneyProp={money}
    //       person={personFormData}
    //       licensee={licenseeFormData}
    //       user={user}
    //       userLicensee={licensee}
    //       naturalPerson={naturalPerson}
    //       selectedLanguage={selectedLanguage ?? ""}
    //       asigneePlan={handleAssigneePlanAndPerson}
    //     />
    //   ),
    // },
  ];

  // async function getPlans(currency: string, is_active: boolean) {
  //   const queryList = [];

  //   queryList.push(`currency=${currency}`);
  //   queryList.push(`is_active=${is_active}`);

  //   try {
  //     await api.get(`/plans?${queryList.join("&")}`).then((response) => {
  //       setPlans(response.data);
  //     });
  //   } catch (error) {
  //     showToast({
  //       type: "error",
  //       message: "Ocorreu um erro ao tentar carregar os planos",
  //     });
  //   }
  // }

  async function resendCode() {
    try {
      setLoading(true);

      const body = {
        email: authFormData?.email,
      };

      await api.post("/users/resend-code", body);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateUser() {
    try {
      setLoading(true);

      const body = {
        email: authFormData?.email,
      };

      await api.post("/users/validate", body);
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitCode(code: string) {
    try {
      setLoading(true);

      if (!authFormData?.email || !code || !authFormData?.password) {
        showToast({
          type: "error",
          message: "Missing data",
        });
        return;
      }

      const body = {
        email: authFormData.email,
        code,
        password: authFormData.password,
      };

      const response = await validateCode(body);
      setUserId(response.id);
      nextStep();
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.validCode"),
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteCode() {
    try {
      setLoading(false);

      const body = {
        email: authFormData?.email,
      };

      await api.post("/users/delete-temporary-user", body);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleCreatePerson(continueAsNatural: boolean) {
    setLoading(true);
    try {
      const body = {
        user: {
          id: userId,
        },
        name: personFormData?.name ?? "",
        cpf: personFormData?.cpf ?? "",
        country: personFormData?.country.id ?? "",
        cellphone: personFormData?.cellphone ?? "",
        state: personFormData?.state ?? "",
        city: personFormData?.city ?? "",
        expertise: personFormData?.expertise ?? "",
        professional_number: personFormData?.professional_number ?? "",
      };

      await api.post("/person", body).then((response) => {
        handleCreateLicensee(continueAsNatural, response.data.id);
        setPersonId(response.data.id);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.errorCreatePerson"),
      });
      return;
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateLicensee(
    continueAsNatural: boolean,
    person_id: string
  ) {
    setLoading(true);
    setNaturalPerson(!!continueAsNatural);

    try {
      const body = {
        person: {
          id: person_id,
        },
        printers: [],
        instagram: "",
        email: continueAsNatural
          ? authFormData?.email
          : licenseeFormData?.email,
        country: continueAsNatural
          ? personFormData?.country?.id
          : licenseeFormData?.country?.id,
        phone: continueAsNatural
          ? personFormData?.cellphone
          : licenseeFormData?.phone ?? "",
        document_number: continueAsNatural
          ? personFormData?.cpf
          : licenseeFormData?.cnpj,
        situation: customerSituations.onboarding,
        city: continueAsNatural ? personFormData?.city : licenseeFormData?.city,
        state: continueAsNatural
          ? personFormData?.state
          : licenseeFormData?.state,
        name: continueAsNatural
          ? personFormData?.name
          : licenseeFormData?.fantasyName,
        manager_name: personFormData?.name,
        companyName: continueAsNatural
          ? personFormData?.name
          : licenseeFormData?.companyName,
        contract: "monthly",
        person_type: continueAsNatural
          ? personTypes.FISICA
          : personTypes.JURIDICA,
        is_public: true,
        termsAccepted: licenseeFormData?.termsAccepted,
      };

      await api.post("/licensees", body).then(async (response) => {
        handleCreateAddress(response.data.id);
        setLicenseeId(response.data.id);
        setLicenseeCountry(response.data.country);
        setSelectedPrice(response.data.country);

        await api
          .get(`/user_terms/${response.data.contract}/active`)
          .then((response) => {
            api
              .post("/users/verify-registration", {
                user_term_id: response.data.user_term.data.id,
              })
              .catch((err) => console.error("err", err));
          });
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.errorCreatePerson"),
      });
      return;
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateAddress(licensee_id: string) {
    setLoading(true);
    try {
      const body = {
        licensee_id,
        type: addressTypes.other,
        address: addressFormData?.address,
        number: addressFormData?.number,
        cep: addressFormData?.cep,
        complement: addressFormData?.complement ?? "",
        district: addressFormData?.district,
        city: addressFormData?.city,
        state: addressFormData?.state,
        country: addressFormData?.country.id,
        billing_address: true,
        deliver_address: true,
      };

      await api.post(`/address`, body);
      signOut();
    } catch (err: any) {
      showToast({
        type: "error",
        message:
          "Não foi possível cadastrar o endereço. Entre em contato com o SAF.",
      });
    } finally {
      setLoading(false);
    }
  }

  function nextStep() {
    if (activeStep === 2) return;
    setActiveStep((prevstate) => prevstate + 1);
  }

  function prevStep() {
    if (activeStep === 1) return;
    setActiveStep((prevstate) => prevstate - 1);
  }

  function handleLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();

    window.localStorage.setItem("i18nextLng", e.target.value);
    i18n.changeLanguage(e?.target.value);

    setSelectedLanguage(e.target.value as typeof selectedLanguage);
  }

  function handlePriceChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedPrice(e.target.value);
  }

  function verifyUserState() {
    var tmpActiveStep = 1;

    if (user?.role) {
      setUserId(user?.id);
      setAuthFormData({
        email: user.email,
        role: user.role,
      });
      tmpActiveStep = 2;
    }

    if (user?.role && user?.person) {
      setPersonId(user?.person?.id);
      const tmpCountry = countries.find((c) => c.code === user.person.country);
      setPersonFormData({
        ...user.person,
        country: {
          id: tmpCountry?.code || "BRA",
          label: tmpCountry?.label || "Brazil",
        },
      });
      // tmpActiveStep = 3;
    }

    // if (user?.role && user?.person && licensee) {
    //   setLicenseeId(licensee?.id);
    //   const tmpCountry = countries.find((c) => c.code === user.person.country);
    //   setLicenseeFormData({
    //     ...licensee,
    //     cnpj: licensee.document_number,
    //     fantasyName: licensee.name,
    //     termsAccepted: !!licensee.user_term,
    //     country: {
    //       id: tmpCountry?.code || "BRA",
    //       label: tmpCountry?.label || "Brazil",
    //     },
    //   });
    //   setLicenseeCountry(licensee.country);
    //   setSelectedPrice(licensee.country);
    //   tmpActiveStep = 4;
    // }

    setActiveStep(tmpActiveStep);
  }

  // useEffect(() => {
  //   if (selectedPrice !== "") {
  //     if (selectedPrice === "BRA") {
  //       setMoney("R$");
  //       getPlans("BRL", true);
  //       return;
  //     }

  //     setMoney("$");
  //     getPlans("USD", true);
  //   }
  // }, [selectedPrice]);

  useEffect(() => {
    verifyUserState();
  }, [user, licensee]);

  // useEffect(() => {
  //   setSelectedPrice(licenseeCountry);
  // }, [licenseeCountry]);

  return (
    <Container>
      <CustomToast />

      <LogoContainer>
        <img src={LogoImg} alt="Logo Fixit" />
      </LogoContainer>

      <FormContainer>
        <StepperContainer>
          <Stepper activeStep={activeStep} steps={steps} />
        </StepperContainer>
      </FormContainer>

      <ChangeLanguageContainer>
        <LanguageSelect
          onChange={(e) => handleLanguageChange(e)}
          value={selectedLanguage || undefined}
        >
          <option value="pt-BR">PT 🇧🇷</option>
          <option value="es">ES 🇪🇸</option>
          <option value="en">EN 🇬🇧</option>
        </LanguageSelect>
      </ChangeLanguageContainer>

      <ChangePriceContainer>
        <PriceSelect
          onChange={(e) => {
            handlePriceChange(e);
          }}
          defaultValue={selectedPrice}
          value={selectedPrice}
        >
          <option value="BRA">💰 BRL</option>
          <option value="USD">💰 US</option>
        </PriceSelect>
      </ChangePriceContainer>
    </Container>
  );
}
